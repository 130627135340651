import React, { Component } from 'react';
import { Dropdown, Layout, Menu, Typography } from 'antd';

import menuLogo from '../resources/menu.svg';
import facebook from '../resources/facebook.svg';
import logo from "../resources/logo.png"
import chat from "../resources/chat.svg"
import settings from "../resources/settings.svg"
import profile from "../resources/profile.svg"
import footer from '../engine/footer';
import Points from './widgets/Points';

const { Header, Footer, Content } = Layout;

const MenuLink = ({ title, key, active, navigate, ...props }) => {
    return (
        <Typography.Link
            className={active ? `menu-link-active` : `menu-link`}
            onClick={() => navigate(key)}
        >
            {title}
        </Typography.Link>
    );
};


class LayoutMobile extends Component {
    render() {
        const { props } = this;

        const { user } = props.client;

        const height = this.props.headHeight ?? 300;
        const vh = this.props.headVh ?? 25;
        const routeKey = props.route.length ? props.route[0] : null;

        return (
            <Layout>
                <Header style={{ height: 55 }}>
                    <div style={{
                        zIndex: 999,
                        background: `#fff`,
                        position: `fixed`,
                        height: 55,
                        right: 0,
                        left: 0,
                        top: 0,
                        boxShadow: `0px 0px 22px rgba(0, 0, 0, 0.25)`,
                    }}>
                        <img
                            src={logo}
                            alt="Zolmaniem"
                            style={{
                                position: `absolute`,
                                height: 40,
                                left: 20,
                                top: 5,
                            }}
                        />
                        <div
                            style={{
                                padding: 0,
                                position: `absolute`,
                                top: 0,
                                bottom: 0,
                                right: 10,
                                display: `flex`,
                                paddingTop: 5,
                            }}
                        >
                            <table className="user-nav">
                                <tr>
                                    <td rowspan="2">
                                        <img
                                            src={profile}
                                            alt="profile"
                                            style={{
                                                height: 22,
                                            }}
                                        />
                                    </td>
                                    <td className="mobile-hide" style={{
                                        paddingBottom: 0,
                                        fontWeight: `bold`,
                                        fontSize: 13,
                                        verticalAlign: `bottom`
                                    }}>
                                        {user.name}
                                    </td>
                                    <td rowspan="2" style={{ padding: `0 10px` }}>
                                        <Dropdown
                                            overlay={(
                                                <Menu>
                                                    <Menu.Item key="0">
                                                        Iestatījumi
                                                    </Menu.Item>
                                                    <Menu.Item key="1" onClick={() => user.logout()}>
                                                        Iziet
                                                    </Menu.Item>
                                                </Menu>
                                            )}
                                            trigger={['click']}
                                        >
                                            <img
                                                src={settings}
                                                alt="settings"
                                                style={{
                                                    height: 22,
                                                    cursor: `pointer`,
                                                }}
                                            />
                                        </Dropdown>
                                    </td>
                                    <td rowspan="2" style={{ padding: `0 10px 0 5px` }}>
                                        <img
                                            src={chat}
                                            alt="chat"
                                            style={{
                                                height: 22,
                                            }}
                                        />
                                    </td>
                                    <td rowspan="2">
                                        <img
                                            src={menuLogo}
                                            alt="menu"
                                            style={{
                                                height: 22,
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="mobile-hide" style={{
                                        paddingTop: 0,
                                        fontSize: 13,
                                        verticalAlign: `top`
                                    }}>
                                        <Points points={user.zd} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </Header>
                <Layout>
                    <Content className="main-content">
                        {this.props.head ?? <></>}
                        <div
                            style={{
                                background: `white`,
                                position: `relative`,
                                padding: `10px 25px`,
                                marginTop: !!this.props.head ? `max(${vh}vh, ${height}px)` : 0,
                                width: `100%`,
                                minHeight: `calc(${!!this.props.head ? 75 : 100}vh - ${height - 100}px)`,
                                overflowY: `auto`,
                            }}
                        >
                            {this.props.children}
                        </div>
                    </Content>
                </Layout>
                <Footer style={{ height: 200 }}>
                    <div
                        style={{
                            position: `absolute`,
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            padding: 20
                        }}
                    >
                        {
                            footer.map((item) => {
                                return (
                                    <>
                                        <Typography.Link
                                            key={item.key}
                                            onClick={() => {
                                                if (`onClick` in item) {
                                                    item.onClick();
                                                } else if (item.type === `link` && `href` in item) {
                                                    this.props.navigate(item.href);
                                                }
                                            }}
                                            style={{
                                                textTransform: `uppercase`,
                                                color: `#F3DD8E`,
                                                margin: `3px 0`,
                                                display: `inline-block`,
                                                ...(`style` in item ? item.style : {})
                                            }}
                                        >
                                            {item.title}
                                            {
                                                !!(`icon` in item) && item.icon
                                            }
                                        </Typography.Link>
                                        <br />
                                    </>
                                );
                            })
                        }
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/zolmaniem.lv"
                            style={{
                                position: `absolute`,
                                top: 10,
                                right: 10,
                            }}
                        >
                            <img src={facebook} alt="facebook" />
                        </a>
                        <span style={{
                            display: `block`,
                            color: `#F3DD8E`,
                            marginTop: 10,
                        }}>
                            Zolmaniem © 2024 Visas tiesības aizsargātas
                        </span>
                    </div>
                </Footer>
            </Layout >
        );
    }
}

export default LayoutMobile;
