import Echo from "laravel-echo"
import { STATE } from "./client";

export default class WebSock
{
    echo = null;
    pusher = null;

    client = null;

    ready = false;
    authorized = false;

    _onReady = [];
    _onClose = [];
    _onAuthorized = [];

    constructor(client) {
        this.client = client;
    }

    connect = async (
        force = false,
        callback = (() => console.log(`Websocket connected.`)),
        fallback = ((state) => { console.warn(`Websocket fallback because websocket ${state}.`); this.connect(true); }),
    ) => {
        if (!this.echo || force) {
            this.disconnect(force);

            this.pusher = require('pusher-js');
 
            this.echo = new Echo({
                broadcaster: 'pusher',
                key: process.env.REACT_APP_PUSHER_KEY,
                wsHost: window.location.hostname,
                wsPort: 6001,
                enabledTransports: [`ws`],
                forceTLS: false,
            });

            this.echo.connector.pusher.connection.bind(`state_change`, (state) => {
                switch (state.current.toLowerCase()) {
                    case `initialized`:
                    case `connecting`:
                        this.ready = false;
                        break;

                    case `connected`:
                        this.ready = true;
                        callback();
                        for (const cb of this._onReady) {
                            cb(this);
                        }
                        this._onReady = [];
                        break;
                    
                    case `failed`:
                    case `unavailable`:
                        this.ready = false;
                        fallback(state.current.toUpperCase());

                        for (const cb of this._onClose) {
                            cb(this);
                        }
                        this._onClose = [];
                        break;

                    case `disconnected`:
                    default:
                        this.ready = false;
                        console.warn(`Websocket client has ${state.current.toLowerCase()}.`);

                        for (const cb of this._onClose) {
                            cb(this);
                        }
                        this._onClose = [];
                        break;
                }
            });
        }

        return this;
    }

    subscribe = ( channel, callback = (p, c) => console.log(`Joined channel: ${c}"`) ) => {
        if (this.ready) {
            const ret = this.echo.channel(channel);
            ret.whisper = function (eventName, data) {
                this.pusher.send_event(eventName, data, this.name);
       
                return this;
            }
            ret.on(`auth.failed`, () => setTimeout(async () => {
                this.client.user.key = null;
                this.client.user.authorized = false;
                this.client.user.refresh(false);
                this.client.setState( STATE.WEBSOCK_AUTH_FAILED );
            }, 100));
            ret.on(`auth.success`, (e) => {
                this.client.setState( STATE.WEBSOCK_AUTH_SUCCESS );
                this.authorized = true;
                for (const cb of this._onAuthorized) {
                    cb(this, e);
                }
                this._onAuthorized = [];
            });
            ret.subscribed(async () => {
                callback(ret, channel);
            });

            return ret;
        }

        return null;
    };

    unsubscribe = ( channel, callback = (p, c) => console.log(`Unsubscribed from ${c}`) ) => {
        if (this.ready) {
            this.echo.leaveChannel(channel);
            callback(this, channel);
        }

        return this;
    };

    disconnect = async (force = false) => {
        if (this.ready || force) {
            try {
                this.echo?.connector?.pusher?.disconnect();
            } catch (ex) {
                console.error(ex);
            }

            this.ready = false;
            this.echo = null;
        }

        return this;
    }

    set onAuthorized (cb) {
        if (this.authorized) {
            cb(this);
        } else {
            this._onAuthorized.push(cb);
        }
    }

    set onReady (cb) {
        if (this.ready) {
            cb(this);
        } else {
            this._onReady.push(cb);
        }
    }

    set onClose (cb) {
        if (!this.ready) {
            cb(this);
        } else {
            this._onClose.push(cb);
        }
    }
}
