import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, Table, Typography } from 'antd';
import React from 'react';
import Layout from '../layout';
import moment from 'moment';
import Points from '../layout/widgets/Points';
import { required } from '../engine/client';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import logo from '../resources/logo.png';
import timer from '../resources/timer.svg';
import calendar from '../resources/calendar.svg';
import clock from '../resources/clock.svg';

const { Link } = Typography;

class Statistics extends Layout {
    constructor(props) {
        super(props);

        this.state = {
            statistics: [],
        };
    }

    content() {
        const { size } = this.props;
        if (size > 1380) {
            return this.contentWide();
        }

        return this.contentWide();
    }

    head() {
        return null;
    }

    componentDidMount() {
        this.fetchStatistics();
    }

    fetchStatistics = async () => {
        const response = await this.props.client.get( `statistics`, 1, 100 );
        if (response.ok && response.status === 200) {
            this.setState({ statistics: [ ...response.body.users ] });
        }
    }

    contentMobile() {
        return (
            <>
                <Typography.Title
                    level={2}
                    style={{
                        color: `#50027E`,
                        fontSize: 40,
                        fontFamily: `Montserrat`,
                        margin: `35px 0 0 0`,
                        textTransform: `uppercase`,
                        fontWeight: 600,
                    }}
                >
                    Statistika
                </Typography.Title>
            </>
        );
    }

    contentWide() {
        const { user } = this.props.client;

        return (
            <>
                <Typography.Title
                    level={2}
                    style={{
                        color: `#50027E`,
                        fontSize: 40,
                        fontFamily: `Montserrat`,
                        margin: `35px 0 0 0`,
                        textTransform: `uppercase`,
                        fontWeight: 600,
                    }}
                >
                    Statistika
                </Typography.Title>
                <br />
                <Card
                    title={(
                        <>
                            <div
                                style={{
                                    textTransform: `uppercase`,
                                    fontWeight: `bold`,
                                    marginBottom: 10,
                                }}
                            >
                                {user.name}
                            </div>
                            <img src={require(`../resources/statistics/level0small.svg`)?.default} alt="level" /> Spēlētājs<br/>
                        </>
                    )}
                    className="user-popup popup-light"
                >
                    <strong>Vieta: </strong> 1.<br/>
                    <strong>ZD: </strong> <Points points={user.zd} /><br/>
                    <strong>Punkti: </strong> <Points points={0} /><br/>
                    <strong>Zoles: </strong> 0/0 (100%)<br/>
                    <strong>Mazās zoles: </strong> 0/0 (100%)<br/>
                    <strong>Sasniegumi: </strong> 0/144 (0%)<br/>
                </Card>
                <Card title="Kopējā statistika">
                    <Table
                        columns={[
                            {
                                title: `Vieta`,
                                dataIndex: `id`,
                                key: `id`,
                            },
                            {
                                title: `Vārds`,
                                dataIndex: `name`,
                                key: `name`,
                            },
                            {
                                title: `Punkti`,
                                dataIndex: `pts`,
                                key: `pts`,
                            },
                            // {
                            //     title: `Status`,
                            //     dataIndex: `status`,
                            //     key: `status`,
                            // },
                        ]}
                        dataSource={this.state.statistics ?? []}
                        //     {
                        //         id: 1,
                        //         key: 1,
                        //         place: `1`,
                        //         name: `Test 1`,
                        //         pts: 10,
                        //         status: `INGAME`,
                        //     },
                        //     {
                        //         id: 2,
                        //         key: 2,
                        //         place: `2`,
                        //         name: `Test 2`,
                        //         pts: 9, 
                        //         status: `INGAME`,
                        //     },
                        //     {
                        //         id: 3,
                        //         key: 3,
                        //         place: `3`,
                        //         name: `Test 3`,
                        //         pts: 8,
                        //         status: `INGAME`,
                        //     },
                        //     {
                        //         id: 4,
                        //         key: 4,
                        //         place: `4`,
                        //         name: `Test 4`,
                        //         pts: 7,
                        //         status: `INGAME`, 
                        //     },
                        //     {
                        //         id: 5,
                        //         key: 5,
                        //         place: `5`,
                        //         name: `Test 5`,
                        //         pts: 6,
                        //         status: `INGAME`,
                        //     },
                        //     {
                        //         id: 6,
                        //         key: 6,
                        //         place: `6`,
                        //         name: `Test 6`,
                        //         pts: 5,
                        //         status: `INGAME`,
                        //     },
                        //     {
                        //         id: 7,
                        //         key: 7,
                        //         place: `7`,
                        //         name: `Test 7`,
                        //         pts: 4,
                        //         status: `WAITING`,
                        //     },
                        //     {
                        //         id: 8,
                        //         key: 8,
                        //         place: `8`,
                        //         name: `Test 8`,
                        //         pts: 3,
                        //         status: `READY`,
                        //     },
                        //     {
                        //         id: 9,
                        //         key: 9,
                        //         place: `9`,
                        //         name: `Test 9`,
                        //         pts: 2,
                        //         status: `LEFT`,
                        //     },
                        //     {
                        //         id: 10,
                        //         key: 10,
                        //         place: `10`,
                        //         name: `Test 10`,
                        //         pts: 1,
                        //         status: `LEFT`,
                        //     },
                        //     {
                        //         id: 11,
                        //         key: 11,
                        //         place: `11`,
                        //         name: `Test 11`,
                        //         pts: 0,
                        //         status: `LEFT`,
                        //     },
                        // ]}
                    />
                </Card>
            </>
        );
    }
}

export default Statistics;
