import React, { Component } from 'react';
import { Dropdown, Layout, Menu, Typography } from 'antd';
import menu from '../engine/menu';

import facebook from '../resources/facebook.svg';
import logo from "../resources/logo.png"
import chat from "../resources/chat.svg"
import settings from "../resources/settings.svg"
import profile from "../resources/profile.svg"
import footer from '../engine/footer';
import Points from './widgets/Points';

const { Header, Footer, Content } = Layout;

const MenuLink = ({ title, url, active, navigate, ...props }) => {
    return (
        <Typography.Link
            className={active ? `menu-link-active` : `menu-link`}
            onClick={() => navigate(`/${url}`, `Zolmaniem | ${title}`)}
        >
            {title}
        </Typography.Link>
    );
};

class LayoutWide extends Component {
    render() {
        const { props } = this;

        const { user } = props.client;
        
        const routeKey = props.route.length ? props.route[0] : null;

        return (
            <Layout>
                <Header>
                    <div style={{
                        zIndex: 999,
                        background: `#fff`,
                        position: `fixed`,
                        height: 100,
                        right: 0,
                        left: 0,
                        top: 0,
                        boxShadow: `0px 0px 22px rgba(0, 0, 0, 0.25)`,
                    }}>
                        <img
                            src={logo}
                            alt="Zolmaniem"
                            style={{
                                position: `absolute`,
                                height: 56,
                                left: 69,
                                top: 22,
                            }}
                        />
                        <div
                            style={{
                                position: `absolute`,
                                top: 0,
                                left: 300,
                                bottom: 0,
                                padding: 0,
                            }}
                        >
                            {
                                menu.map((item) =>
                                    <MenuLink
                                        active={routeKey === `/${item.key}`}
                                        navigate={props.navigate}
                                        url={item.key}
                                        {...item}
                                    />
                                )
                            }
                            {
                                user.roles.includes(`Administrators`) && (
                                    <MenuLink
                                        active={routeKey === `/admin`}
                                        navigate={props.navigate}
                                        url={`admin`}
                                        title={`Admin`}
                                    />
                                )
                            }
                        </div>
                        <div
                            style={{
                                padding: 0,
                                position: `absolute`,
                                top: 0,
                                bottom: 0,
                                right: `min(15vw, calc((100vw - 1300px) / 2))`,
                                display: `flex`,
                                paddingTop: 40,
                            }}
                        >
                            <table className="user-nav">
                                <tr>
                                    <td rowspan="2">
                                        <img
                                            src={profile}
                                            alt="profile"
                                            style={{
                                                height: 33,
                                            }}
                                        />
                                    </td>
                                    <td style={{ fontWeight: `bold` }}>{user.name}</td>
                                    <td rowspan="2" style={{ padding: `0 15px` }}>
                                        <Dropdown
                                            overlay={(
                                                <Menu>
                                                    <Menu.Item key="0">
                                                        Iestatījumi
                                                    </Menu.Item>
                                                    <Menu.Item key="1" onClick={() => user.logout()}>
                                                        Iziet
                                                    </Menu.Item>
                                                </Menu>
                                            )}
                                            trigger={['click']}
                                        >
                                            <img
                                                src={settings}
                                                alt="settings"
                                                style={{
                                                    cursor: `pointer`,
                                                    height: 33,
                                                }}
                                            />
                                        </Dropdown>
                                    </td>
                                    <td rowspan="2">
                                        <img
                                            src={chat}
                                            alt="chat"
                                            style={{
                                                height: 33,
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td><Points points={user.zd} /></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </Header>
                <Layout>
                    <Content className="main-content">
                        <div
                            style={{
                                background: `white`,
                                position: `relative`,
                                padding: `10px 25px`,
                                margin: `70px auto 0 auto`,
                                width: `70%`,
                                minHeight: `calc(100vh - 270px)`,
                                minWidth: 1300
                            }}
                        >
                            {this.props.children}
                        </div>
                    </Content>
                </Layout>
                <Footer>
                    <div
                        style={{
                            position: `absolute`,
                            right: `min(15vw, calc((100vw - 1300px) / 2))`,
                            left: `min(15vw, calc((100vw - 1300px) / 2))`,
                            top: 0,
                            bottom: 0,
                            padding: 20
                        }}
                    >
                        {
                            footer.map((item) => {
                                return (
                                    <Typography.Link
                                        key={item.key}
                                        onClick={() => {
                                            if (`onClick` in item) {
                                                item.onClick();
                                            } else if (item.type === `link` && `href` in item) {
                                                this.props.navigate(item.href);
                                            }
                                        }}
                                        style={{
                                            textTransform: `uppercase`,
                                            color: `#F3DD8E`,
                                            margin: `0 10px 0 0`,
                                            ...(`style` in item ? item.style : {})
                                        }}
                                    >
                                        {item.title}
                                        {
                                            !!(`icon` in item) && item.icon
                                        }
                                    </Typography.Link>
                                );
                            })
                        }
                        <a href="https://www.facebook.com/zolmaniem.lv" target="_blank" rel="noreferrer">
                            <img src={facebook} alt="facebook" />
                        </a>
                        <br />
                        <span style={{
                            display: `block`,
                            color: `#F3DD8E`,
                            marginTop: 10,
                        }}>
                            Zolmaniem © 2024 Visas tiesības aizsargātas
                        </span>
                    </div>
                </Footer>
            </Layout >
        );
    }
}

export default LayoutWide;
