import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, message, notification, Row, Select, Space, Typography } from 'antd';
import React from 'react';
import Layout from '../layout';
import moment from 'moment';
import Points from '../layout/widgets/Points';
import { required } from '../engine/client';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import logo from '../resources/logo.png';
import timer from '../resources/timer.svg';
import calendar from '../resources/calendar.svg';
import clock from '../resources/clock.svg';

const { Link } = Typography;

class NewTourney extends Layout {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    content() {
        const { size } = this.props;
        if (size > 1380) {
            return this.contentWide();
        }

        return this.contentWide();
    }

    head() {
        return null;
    }

    contentMobile() {
        return (
            <>
                <Typography.Title
                    level={2}
                    style={{
                        color: `#50027E`,
                        fontSize: 40,
                        fontFamily: `Montserrat`,
                        margin: `35px 0 0 0`,
                        textTransform: `uppercase`,
                        fontWeight: 600,
                    }}
                >
                    Jauns turnīrs
                </Typography.Title>
            </>
        );
    }

    contentWide() {
        return (
            <>
                <Typography.Title
                    level={2}
                    style={{
                        color: `#50027E`,
                        fontSize: 40,
                        fontFamily: `Montserrat`,
                        margin: `60px 0 30px 0`,
                        textTransform: `uppercase`,
                        fontWeight: 600,
                    }}
                >
                    Jauns turnīrs
                </Typography.Title>
                <Form
                    initialValues={{
                        // games: 6,
                        // stages: 9,
                        // type: `GM`,
                        public: true,
                        fast: true,
                    }}
                    onFinish={async (data) => {
                        const response = await this.props.client.post(`tourneys`, { ...data, cycle: data.cycle.map(d => d.format(`YYYY-MM-DD HH:mm:00`)) });
                        if (response.ok && response.status === 201 && response.body.id) {
                            this.props.navigate(`/tourney/${response.body.id}`, `Zolmaniem | Turnīrs`);
                            message.success( `Turīrs izveidots.` );
                        } else {
                            notification.destroy();
                            notification.error({
                                duration: 60,
                                message: response.body.title ?? `Mēģiniet vēlreiz!`,
                                description: response.body.error ?? `Kaut kas nogāja greizi - neizdevās izveidot turnīru.`,
                            });
                        }
                    }}
                    layout="vertical"
                >
                    <Row gutter={10}>
                        <Col span={12} sm={12}>
                            <Card title="Par turnīru">
                                <Form.Item
                                    name="public"
                                    valuePropName="checked"
                                    style={{ textAlign: `left` }}
                                >
                                    <Checkbox>
                                        <span>
                                            Publisks
                                        </span>
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item
                                    name="quick"
                                    valuePropName="checked"
                                    style={{ textAlign: `left` }}
                                >
                                    <Checkbox>
                                        <span>
                                            Ātrā spēle
                                        </span>
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item label="Nosaukums" name="name" rules={[required]}>
                                    <Input placeholder="Norādiet nosaukumu" style={{ minWidth: 150, maxWidth: 350 }} />
                                </Form.Item>
                                <Form.Item label="Spēles veids" name="gameType" rules={[required]}>
                                    <Select
                                        placeholder="Norādiet spēles veidu"
                                        options={
                                            [`P`, `G`, `PM`, `GM`].map(val => ({ value: val, label: <span>{val}</span> }))
                                        }
                                        style={{ minWidth: 150, maxWidth: 350 }}
                                    />
                                </Form.Item>
                                <Form.Item label="Spēļu skaits" name="games" rules={[required]}>
                                    <InputNumber min={1} max={100} placeholder="Norādiet spēļu skaitu kārtā" style={{ minWidth: 250, maxWidth: 350 }} />
                                </Form.Item>
                                <Form.Item label="Kārtu skaits" name="stages" rules={[required]}>
                                    <InputNumber min={1} max={100} placeholder="Norādiet kārtu skaitu posmā" style={{ minWidth: 250, maxWidth: 350 }} />
                                </Form.Item>
                                {/* <Form.Item label="ZD likme" name="stages" rules={[required]}>
                                    <InputNumber min={0} max={9999999} placeholder="Norādiet ZD likmi spēlē" />
                                </Form.Item> */}
                            </Card>
                        </Col>
                        <Col span={12} sm={12}>
                            <Card title="Par balvām">
                                <Form.Item label="Balvas fonds" name="price" rules={[required]}>
                                    <InputNumber placeholder="Norādiet balvas fondu" addonAfter="ZD" style={{ minWidth: 250, maxWidth: 350 }} />
                                </Form.Item>
                                <Form.Item label="Ieejas maksa" name="fee" rules={[required]}>
                                    <InputNumber placeholder="Norādiet ieejas maksu" addonAfter="ZD" style={{ minWidth: 250, maxWidth: 350 }} />
                                </Form.Item>
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={10}>
                        <Col span={12} sm={12}>
                            <Card title="Par organizatoru">
                                <Form.Item label="Pilns vārds vai nosaukums" name="orgName" rules={[required]}>
                                    <Input placeholder="Norādiet organizatora nosaukums" style={{ minWidth: 150, maxWidth: 350 }} />
                                </Form.Item>
                                <Form.Item label="E-pasts" name="orgEmail">
                                    <Input placeholder="Norādiet organizatora e-pastu" style={{ minWidth: 150, maxWidth: 350 }}/>
                                </Form.Item>
                                <Form.Item label="Tālruņa numurs" name="orgPhoneNr">
                                    <Input placeholder="Norādiet organizatora tālr. nr." style={{ minWidth: 150, maxWidth: 350 }}/>
                                </Form.Item>
                            </Card>
                        </Col>
                        <Col span={12} sm={12}>
                            <Card title="Norises laiks">
                                <Form.List name="cycle">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {
                                                fields.map(({ key, name, ...restField }) => (
                                                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name]}
                                                            rules={[required]}
                                                        >
                                                            <DatePicker format="DD.MM.YYYY HH:mm" showTime={{ format: 'HH:mm' }} placeholder="Posma norises laiks"  style={{ minWidth: 150, maxWidth: 350 }} />
                                                        </Form.Item>
                                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                                    </Space>
                                                ))
                                            }
                                            <Form.Item>
                                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{ minWidth: 150, maxWidth: 450 }}>
                                                    Pievienot
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </Card>
                        </Col>
                    </Row>
                    <Button
                        type="primary"
                        className="button"
                        htmlType="submit"
                        style={{
                            width: `fit-content`,
                            margin: `15px`,
                        }}
                    >
                        Izveidot
                    </Button>
                </Form>
            </>
        );
    }
}

export default NewTourney;
