import { Button, Modal } from 'antd';
import React from 'react';
import Layout from '../layout';
import GameLogs from '../layout/widgets/GameLogs';

class Play extends Layout {
    constructor(props) {
        super(props);

        this.logs = [];
        this.doAsk = true;

        this.state = {
            showProtocol: false,
            drag: null,
            cards: [],
            decision: [],
            draggable: [null, null],
            players: [],
            lobby: [],
            cycle: null,
            lastHand: null,
            viewLastHand: false,
            lastGame: false,
        };
    }

    tables = [
        {
            width: 1919,
            height: 1060,
            shadow: {
                left: [115, 1900],
                top: [400, 1060],
            },
            box: {
                left: [346, 1828],
                top: [400, 1000],
                center: {
                    x: 1090,
                    y: 590,
                }
            },
            getYoffset: (x) => 11 + (-9.5 - 11) / (1 + ((10 - (x * 10)) / 4)) * 2.5,
        }
    ];

    componentDidMount() {
        const { ws: websock } = this.props.client;
        
        const lobbyId = [ ...this.props.route ?? [`null`] ].pop();
        if (!this.props.client.game?.id) {
            websock.onAuthorized = (ws, e) => {
                websock.subscribe(`lobby.${lobbyId}`)
                    .on(`game.invite`, (e) => {
                        this.props.client.game = { id: e.game.id };
                        this.connectGame(e.game.id);
                    });
            };
        } else {            
            this.connectGame(this.props.client.game.id);
        }
    }

    cleanBoard() {
        // ..
    }

    connectGame(id) {
        this.cleanBoard();
        const { game, ws: websock } = this.props.client;
        websock.subscribe(`game.${id}`)
            .on(`cards.dealt`, (e) => {
                websock.subscribe(`game.${game.id}`).whisper(`cards.list`, { sort: null });
            })
            .on(`cards.sorted`, (e) => {
                if (e.cards) {
                    this.setState({ cards: [...e.cards] });
                }
            })
            .on(`game.joined`, (e) => {
                const { state } = this;
                const players = state.players.map(p => p.id === e.user.id ? { ...e.user } : { ...p });
                this.setState({ players: [...players] });
            })
            .on(`players.list`, (e) => {
                this.setState({ players: [...e.players] });
            })
            .on(`last.game`, (e) => {
                this.setState({ lastGame: true });
            })
            .on(`last.game_failed`, (e) => {
                this.setState({ lastGame: false });
            })
            .on(`game.play`, (e) => {
                this.setState({
                    players: [ ...e.players ],
                    cards: [ ...e.cards ],
                    timer: e.timer,
                    cycle: { ...e.cycle },
                    lastHand: e.lastHand ? { ...e.lastHand } : null,
                });
            })
            .on(`game.playing`, (e) => {
                this.setState({
                    players: [ ...e.players ],
                    timer: null,
                    cycle: { ...e.cycle },
                    lastHand: e.lastHand ? { ...e.lastHand } : null,
                });
            })
            .on(`card.played`, (e) => {
                this.setState({
                    players: [ ...e.players ],
                    timer: null,
                    cycle: { ...e.cycle },
                    lastHand: e.lastHand ? { ...e.lastHand } : null,
                });
            })
            .on(`game.info`, (e) => {
                this.log(e.logs);
                this.setState({ players: [...e.players] });
            })
            .on(`game.choosing`, (e) => {
                this.setState((old) => ({ players: [ ...old.players.map(p => p.id === e.user.id ? { ...p, isMove: true, role: 'RUNĀ' } : { ...p, isMove: false }) ] }));
            })
            .on(`game.choice`, (e) => {
                this.setState({ decision: [...e.choices] });
            })
            .on(`game.finished`, (e) => {
                // ...
            })
            .on(`game.invite`, (e) => {
                this.props.client.game = { id: e.game.id };
                websock.unsubscribe(`game.${id}`);
                this.connectGame(e.game.id);
            });;
    }

    log = (messages) => {
        this.logs = [...this.logs, ...messages];
        // this.forceUpdate();
    };

    playCard = async (value) => {
        const { state } = this;
        const { game, ws: websock } = this.props.client;

        if (state.cycle?.type === `TABLE` && state.cycle?.moves?.length < 2) {
            const cycle = { ...state.cycle };
            
            if (!cycle.moves[0]) {
                cycle.moves[0] = { answer: value };
            } else if (!cycle.moves[1]) {
                cycle.moves[1] = { answer: value };
            }

            this.setState({
                cards: [ ...state.cards.map(c => ({ ...c, active: cycle.moves.length < 2, stashed: c.stashed || c.card === value })) ],
                cycle: { ...cycle },
            });

        } else {
            this.setState({
                cards: [ ...state.cards.filter(c => c.card !== value ).map(c => ({ ...c, active: false })) ],
                cycle: state.cycle ? { ...state.cycle, moves: [ ...state.cycle.moves, { answer: value } ]} : null,
            });
            websock.subscribe(`game.${game.id}`).whisper(`card.play`, { card: value });
        }
    };

    content() {
        const { state } = this;
        const { game, user, ws: websock } = this.props.client;

        // console.log({ ...state });

        const cards = state.cards.filter(card => !card.stashed);

        const screen = {
            width: document.body.offsetWidth,
            height: document.body.offsetHeight,
            ratio: document.body.offsetWidth / document.body.offsetHeight,
        };

        const table = {
            size: {
                width: 0,
                height: 0,
                ratio: 0,
            },
            box: {
                width: 0,
                height: 0,
            },
            offset: {
                x: 0,
                y: 0,
            },
            position: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
            },
            center: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
            },
        };

        const oTable = this.tables[0];

        const headerSize = screen.width > 1380 ? 100 : 55;

        table.box.height = screen.height - headerSize;
        if (screen.ratio <= 1) {
            table.box.height -= 140;
            // table.box.height *= 1 - screen.ratio;
            table.box.height *= 0.55 + screen.ratio / 10;
            table.position.bottom = (screen.height - screen.width) * 0.05;
            // table.size.width = table.size.height * (oTable.box.left[1] - oTable.box.left[0]) / (oTable.box.top[1] - oTable.box.top[0]);
        } else {
            table.box.height *= 0.55 + screen.ratio / 10;
            // table.position.bottom
            // table.size.width = table.size.height * (oTable.shadow.left[1] - oTable.shadow.left[0]) / (oTable.shadow.top[1] - oTable.shadow.top[0]);
        }

        table.box.width = table.box.height * ((oTable.shadow.left[1] - oTable.shadow.left[0]) / (oTable.shadow.top[1] - oTable.shadow.top[0]));

        table.size.height = table.box.height * (oTable.height / (oTable.shadow.top[1] - oTable.shadow.top[0]));
        table.size.width = table.box.width * (oTable.width / (oTable.shadow.left[1] - oTable.shadow.left[0]));

        table.offset.y = -((table.size.height - table.box.height));
        table.offset.x = -((table.size.width - table.box.width));

        if (table.box.width > screen.width) {
            // table.position.right = 
            table.position.right = -((oTable.width - oTable.box.center.x) * table.size.width / oTable.width) + screen.width / 2;
            // table.offset.x[0] = -((oTable.box.center.x) * table.size.ratio);
            // table.offset.x[1] = 0;
            // table.offset.x[1] = ((oTable.box.center.x) * table.size.ratio);
        } else if (table.box.width * 1.2 > screen.width) {
            table.position.right = -(screen.width - table.box.width) * 0.3 -
                ((oTable.width - oTable.box.left[1]) * table.size.width / oTable.width);
        }

        // table.position.bottom = 0;
        // table.position.right = 0;
        // table.position.left = document.body.offsetWidth - (table.size.width + table.position.right);
        // table.position.top = document.body.offsetHeight - (table.size.height + table.position.bottom);

        table.center.bottom = (oTable.height - oTable.box.center.y) * table.size.height / oTable.height + table.position.bottom;
        table.center.right = (oTable.width - oTable.box.center.x) * table.size.width / oTable.width + table.position.right;
        table.center.left = screen.width - table.center.right;
        table.center.top = screen.height - table.center.bottom;

        const card = {
            width: table.box.height * 0.5 * 0.718,
            height: table.box.height * 0.5,
        };

        let fullHandWidth = (10 - 1) * card.width * 0.45;
        let cardsMaxWidth = card.width;
        if (cards.length > 1) {
            cardsMaxWidth += (cards.length - 1) * card.width * 0.45;
        }

        const left = screen.width - (table.box.width + table.position.right) + ((oTable.box.left[0] - oTable.shadow.left[0]) * table.size.width / oTable.width);
        const top = screen.height - (table.box.height + table.position.bottom) + ((oTable.box.top[0] - oTable.shadow.top[0]) * table.size.height / oTable.height);

        const box = [];
        box[0] = left > 0 ? left : 0;


        const logsStyle = {
            left: screen.width > 1380 ? screen.width * 0.03 : 20,
            top: screen.width < 900 ? 120 : undefined,
            bottom: screen.width > 1380 ? 145 : `unset`,
        };

        if (screen.width * 0.9 - 450 > card.width + 9 * card.width * 0.45 || screen.width > 1380) {
            logsStyle.top = `unset`;
            logsStyle.bottom = screen.width > 1380 ? 145 : 50;
            box[0] += 450;
        } else if (table.center.left > 350 && screen.width <= 1380) {
            logsStyle.top = `unset`;
            logsStyle.bottom = table.box.height * 0.4;
        }

        box[1] = box[0] + table.box.width < screen.width ? box[0] + table.box.width : screen.width;

        let cContainerW = box[1] - box[0] - screen.width * 0.1;

        fullHandWidth = Math.min(cContainerW, fullHandWidth);
        cContainerW = Math.min(cContainerW, cardsMaxWidth);

        const tBox = {
            width: box[1] - box[0],
            height: table.box.height,
            left: box[0],
            top: top,
        };

        const players = {
            left: null,
            middle: null, // if 3 players middle is always table
            right: null,
            me: null,
        };

        const playedCards = [];

        let playerId = 0;
        for (const i in state.players) {
            if (state.players[i].id.toString() === user.id.toString()) {
                playerId = parseInt(i);
                break;
            }
        }

        if (
            state.cycle &&
            state.players &&
            Array.isArray(state.players) &&
            Array.isArray(state.cycle.moves) &&
            state.cycle.moves.length >= 1 &&
            state.cycle.type !== 'TABLE' &&
            state.cycle.first !== null
        ) {
            for (let i = 0; i < Math.min(state.cycle.moves.length, state.players.length); i++) {
                let lcId = state.cycle.first + i;
                if (lcId >= state.players.length) {
                    lcId -= state.players.length;
                }

                if (lcId in state.players) {
                    state.players[lcId].card = state.cycle.moves[i].answer;
                    if (state.lastHand && state.lastHand.winner === i) {
                    }
                }
            }
        }

        if (
            state.players &&
            state.lastHand &&
            Array.isArray(state.players) &&
            Array.isArray(state.lastHand.moves) &&
            state.lastHand.moves.length >= 3 &&
            state.lastHand.type !== `TABLE`
        ) {
            state.players[state.lastHand.winner].winner = { ...state.lastHand };
        }

        for (let i = 0; i < state.players.length; i++) {
            let lcId = playerId + i;
            if (lcId >= state.players.length) {
                lcId -= state.players.length;
            }

            const player = state.players[lcId];

            if (player && player.name) {
                if (players.me === null && i === 0) {
                    players.me = { ...player };
                } else if (players.left === null) {
                    players.left = { ...player };
                } else if (players.middle === null && state.lobby && state.lobby.players === 4) {
                    players.middle = { ...player };
                } else if (players.right === null) {
                    players.right = { ...player };
                }
            }
        }

        // console.log({ ...players });

        if (players.left && players.left.card) {
            playedCards[0] = players.left.card;
        }

        if (players.middle && players.middle.card) {
            playedCards[1] = players.middle.card;
        }

        if (players.right && players.right.card) {
            playedCards[2] = players.right.card;
        }

        if (players.me && players.me.card) {
            playedCards[3] = players.me.card;
        }

        return (
            <>
                <div
                    className="background-1"
                    style={{ top: headerSize }}
                >
                    {
                        this.promptDecision(state.decision ?? [])
                    }
                    {
                        this.playTable(table)
                    }
                    <div className="game-info" style={{ background: state.showProtocol ? `rgba(0, 0, 0, 0.8)` : undefined }}>
                        <div className="game-btn-container">
                            <div className="btn" onClick={() => this.setState(old => ({ showProtocol: !old.showProtocol }))}>{screen.width > 525 ? `Spēles ` : ``}protokols</div>
                            <div className="btn">Sadalījums</div>
                            <div
                                className={`primary btn${state.lastGame ? ` inactive` : ``}`}
                                onClick={() => {
                                    if (!state.lastGame) {
                                        websock.subscribe(`game.${game.id}`).whisper(`last.game`);
                                        this.setState({ lastGame: true });
                                    }
                                }}
                            >
                                Pēdējā partija
                            </div>
                        </div>
                        <div className="game-points">| -1 | + 14 | +4 | -13 |</div>
                        {
                            state.showProtocol && (
                                <table style={{ opacity: 1 }}>
                                    <tr>
                                        <td style={{ minWidth: 20, width: 20 }}></td>
                                        <td>Jānis Paraudziņš</td>
                                        <td>Jānis Paraudziņš</td>
                                        <td>Jānis Paraudziņš</td>
                                        <td>Jānis Paraudziņš</td>
                                        <td>Pules</td>
                                        <td>b</td>
                                    </tr>
                                    <tr>
                                        <td style={{ minWidth: 20, width: 20 }}>1</td>
                                        <td>Jānis Paraudziņš</td>
                                        <td>Jānis Paraudziņš</td>
                                        <td>Jānis Paraudziņš</td>
                                        <td>Jānis Paraudziņš</td>
                                        <td>Pules</td>
                                        <td>b</td>
                                    </tr>
                                    <tr>
                                        <td style={{ minWidth: 20, width: 20 }}>100</td>
                                        <td>Jānis Paraudziņš</td>
                                        <td>Jānis Paraudziņš</td>
                                        <td>Jānis Paraudziņš</td>
                                        <td>Jānis Paraudziņš</td>
                                        <td>Pules</td>
                                        <td>b</td>
                                    </tr>
                                </table>
                            )
                        }
                    </div>
                    <GameLogs logs={this.logs} style={{ ...logsStyle }} />
                    <div
                        className="table-1"
                        style={{
                            backgroundPosition: `${table.offset.x}px ${table.offset.y}px`,
                            backgroundSize: `${table.size.width}px ${table.size.height}px`,
                            width: table.box.width,
                            height: table.box.height,
                            right: table.position.right,
                            bottom: table.position.bottom,
                        }}
                    >
                        {state.cycle === null && this.drawTableCards(tBox, table.center)}
                        {state.cycle && this.drawPlacedCards(playedCards, tBox, table.center)}
                        {
                            players.left && this.drawPlayerCard(0, true, table, players.left)
                        }
                        {
                            players.middle && this.drawPlayerCard(1, true, table, players.middle)
                        }
                        {
                            players.right && this.drawPlayerCard(2, true, table, players.right)
                        }
                    </div>
                    <div style={{
                        position: `fixed`,
                        zIndex: 1004,
                        bottom: table.position.bottom - table.box.height * 0.2 + table.box.height * 0.5 * 0.105 + card.height + 10,
                        right: screen.width - (box[0] + ((box[1] - box[0]) - fullHandWidth) / 2 + fullHandWidth),
                    }}>
                        <div
                            className="player-time"
                            style={{
                                fontSize: screen.width > 1000 ? 40 : 25,
                            }}
                        >
                            30
                        </div>
                        <div
                            className="primary btn"
                            style={{
                                borderRadius: 10,
                                height: screen.width > 500 ? 32 : 23,
                                lineHeight: screen.width > 500 ? `30px` : `20px`,
                                margin: 0
                            }}
                        >
                            ATMESTIES
                        </div>
                    </div>
                    <div
                        style={{
                            zIndex: 1500,
                            opacity: 0.5,
                            backgroundColor: `black`,
                            position: `fixed`,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            padding: 10,
                            color: `white`,
                            textAlign: `right`,
                            pointerEvents: `none`,
                            textTransform: `uppercase`,
                        }}
                    >
                        {this.logs?.length ? [ ...this.logs ].pop() : `Gaidam spēlētājus.`}
                    </div>
                </div>
                <div
                    className="cards"
                    style={{
                        left: box[0] + ((box[1] - box[0]) - cContainerW) / 2,
                        height: table.box.height * 0.5,
                        bottom: table.position.bottom - table.box.height * 0.1,
                        width: cContainerW,
                        position: `fixed`,
                        zIndex: 1050,
                    }}
                >
                    {
                        !!cards.length && cards.map((c, i) => {
                            let offset = 0;
                            const cL = (cContainerW - card.width);
                            if (cards.length > 1 && i > 0) {
                                const sp = cL / (cards.length - 1);
                                offset = sp * i;
                            }

                            return (
                                <div
                                    className={`playing-card${c.active ? ` playing-card-active` : ``}`}
                                    style={{
                                        backgroundImage: `url("${require(`../resources/cards/basic/${c.card}.svg`)}")`,
                                        backgroundSize: `${card.width}px ${card.height}px`,
                                        height: card.height,
                                        width: card.width,
                                        bottom: table.position.bottom - table.box.height * 0.2 + (c.active ? table.box.height * 0.5 * 0.105 : 0),
                                        left: box[0] + ((box[1] - box[0]) - cContainerW) / 2 + offset,
                                    }}
                                    onClick={() => c.active && this.playCard(c.card)}
                                />
                            );
                        })
                    }
                </div>
            </>
        );
    }

    handleDecision = async (value) => {
        const { game, ws: websock } = this.props.client;
        
        this.setState({ decision: [] });
        websock.subscribe(`game.${game.id}`).whisper(`choice.make`, { answer: value });
        // await post(`game`, { decision: value }, this.props.route[1]);
    };

    promptDecision(decisions = []) {
        const screen = {
            width: document.body.offsetWidth,
            height: document.body.offsetHeight,
            ratio: document.body.offsetWidth / document.body.offsetHeight,
        };

        return (
            <Modal
                footer={null}
                closable={false}
                maskClosable={false}
                visible={decisions.length > 1}
                zIndex={1200}
                bodyStyle={{
                    margin: 0,
                    padding: 0,
                    display: `flex`,
                    flexDirection: `column`,
                    justifyContent: `space-around`,
                    backgroundColor: `rgba(0, 0, 0, 0)`,
                    height: 725,
                }}
                width={Math.min(screen.width * 0.9, 500)}
                className="decision-modal"
                destroyOnClose
            >
                {
                    decisions.map((dec, i) => {
                        return (
                            <div
                                className="option"
                                onClick={() => this.handleDecision(dec.key)}
                            >
                                <span>{dec.label}</span>
                            </div>
                        );
                    })
                }
            </Modal>
        );
    }

    playTable(table) {
        const { state } = this;
        const { game, user, ws: websock } = this.props.client;

        const screen = {
            width: document.body.offsetWidth,
            height: document.body.offsetHeight,
            ratio: document.body.offsetWidth / document.body.offsetHeight,
        };

        const card = {
            width: Math.min(screen.width * 0.9, 600) / 2 * 0.65,
            height: Math.min(screen.width * 0.9, 600) / 2 * 0.65 / 0.718,
        };

        return (
            <Modal
                // visible
                closable={false}
                maskClosable={false}
                visible={state.cycle?.type === `TABLE`}
                zIndex={1049}
                bodyStyle={{
                    margin: 0,
                    padding: 0,
                    display: `flex`,
                    backgroundColor: `rgba(0, 0, 0, 0)`,
                    height: 725,
                }}
                width={Math.min(screen.width * 0.9, 600)}
                className="table-modal"
                destroyOnClose
                footer={
                    <div style={{
                        width: `100%`,
                    }}>
                        <Button
                            className="table-modal-button"
                            disabled={state.cycle?.type !== `TABLE` || state.cycle?.moves?.length !== 2}
                            onClick={() => {
                                websock.subscribe(`game.${game.id}`).whisper(`card.play`, { card: state.cycle.moves.map(m => m.answer) });
                                this.setState({
                                    cards: [ 
                                        ...state.cards.filter(c => !state.cycle.moves.map(m => m.answer).includes(c.card))
                                            .map(c => ({ ...c, active: false, stashed: false }))
                                    ],
                                    cycle: null,
                                });
                            }}
                        >
                            NORAKT
                        </Button>
                    </div>
                }
                style={{
                    bottom: table.box.height * 0.5 + (table.position.bottom - table.box.height * 0.1) + 80,
                    top: screen.height - table.box.height * 0.5 + (table.position.bottom - table.box.height * 0.1) > 300 ? 
                        screen.height - table.box.height * 0.5 + (table.position.bottom - table.box.height * 0.1) - 300 : 0,
                    left: (screen.width - Math.min(screen.width * 0.9, 600)) / 2,
                    right: (screen.width - Math.min(screen.width * 0.9, 600)) / 2,
                }}
            >
                <div style={{ 
                    margin: 0,
                    width: `50%`,
                    height: `100%`,
                    minHeight: (screen.height - (table.box.height * 0.5 + table.position.bottom - table.box.height * 0.1 + 10) - table.box.height * 0.15) * 0.35,
                    position: `relative`,
                }}>
                    {
                        state.cycle?.type === `TABLE` && 0 in state.cycle.moves && (
                            <div
                                className="playing-card"
                                style={{
                                    backgroundImage: `url("${require(`../resources/cards/basic/${state.cycle.moves[0].answer}.svg`)}")`,
                                    backgroundSize: `${card.width}px ${card.height}px`,
                                    height: card.height,
                                    width: card.width,
                                    position: `absolute`,
                                    top: `50%`,
                                    left: `50%`,
                                    transform: `translate(-50%, -50%)`,
                                    cursor: `pointer`,
                                }}
                                onClick={() => this.setState({
                                    cards: state.cards.map(c => c.card === state.cycle.moves[0].answer ? { ...c, active: true, stashed: false } : { ...c, active: true }),
                                    cycle: { ...state.cycle, moves: state.cycle.moves.filter((m, i) => i !== 0) },
                                })}
                            />
                        )
                    }
                </div>
                <div style={{
                    margin: 0,
                    width: `50%`,
                    height: `100%`,
                    minHeight: (screen.height - (table.box.height * 0.5 + table.position.bottom - table.box.height * 0.1 + 10) - table.box.height * 0.15) * 0.35,
                    position: `relative`,
                }}>
                    {
                        state.cycle?.type === `TABLE` && 1 in state.cycle.moves && (
                            <div
                                className="playing-card"
                                style={{
                                    backgroundImage: `url("${require(`../resources/cards/basic/${state.cycle.moves[1].answer}.svg`)}")`,
                                    backgroundSize: `${card.width}px ${card.height}px`,
                                    height: card.height,
                                    width: card.width,
                                    position: `absolute`,
                                    top: `50%`,
                                    left: `50%`,
                                    transform: `translate(-50%, -50%)`,
                                    cursor: `pointer`,
                                }}
                                onClick={() => this.setState({
                                    cards: state.cards.map(c => c.card === state.cycle.moves[1].answer ? { ...c, active: true, stashed: false } : { ...c, active: true }),
                                    cycle: { ...state.cycle, moves: state.cycle.moves.filter((m, i) => i !== 1) },
                                })}
                            />
                        )
                    }
                </div>
            </Modal>
        );
    }

    drawTableCards(box, center) {
        const card = {
            width: box.height * 0.2,
            height: box.height * 0.2 * 1.3965, // card ratio
            margin: 5,
        };

        let back = `black-red`;

        return (
            <>
                <div
                    className="table-card"
                    style={{
                        backgroundImage: `url("${require(`../resources/card-backs/${back}/left.svg`)}")`,
                        backgroundSize: `${card.width}px ${card.height}px`,
                        height: card.height,
                        width: card.width,
                        left: center.left - card.width - card.margin,
                        top: center.top - card.height / 2,
                    }}
                />
                <div
                    className="table-card"
                    style={{
                        backgroundImage: `url("${require(`../resources/card-backs/${back}/right.svg`)}")`,
                        backgroundSize: `${card.width}px ${card.height}px`,
                        height: card.height,
                        width: card.width,
                        left: center.left + card.margin,
                        top: center.top - card.height / 2,
                    }}
                />
            </>
        );
    }

    drawPlacedCards(cards, box, center) {
        const screen = {
            width: document.body.offsetWidth,
            height: document.body.offsetHeight,
            ratio: document.body.offsetWidth / document.body.offsetHeight,
        };

        const c = screen.width > 1000 ? 0.3 : 0.25;
        const card = {
            width: box.height * c * 0.718,
            height: box.height * c,
        };

        return (
            <>
                {
                    cards.map((img, i) => {
                        const margin = 10;
                        let left = center.left;
                        let top = center.top - card.height / 2 + card.height * 0.4;

                        switch (i) {
                            default:
                            case 0:
                                left = center.left - margin - card.width - card.width * 1.5 / 2;
                                break;

                            case 1:
                                left = center.left - card.width * 1.5 / 2;
                                top -= card.height * 0.2;
                                break;

                            case 2:
                                left = center.left + margin + card.width * 1.5 / 2;
                                break;

                            case 3:
                                left = center.left + card.width * 1.5 / 2 - card.width;
                                top += card.height * 0.2;
                                break;
                        }

                        return (
                            <div
                                className="playing-card"
                                style={{
                                    backgroundImage: `url("${require(`../resources/cards/basic/${img}.svg`)}")`,
                                    backgroundSize: `${card.width}px ${card.height}px`,
                                    height: card.height,
                                    width: card.width,
                                    left: left,
                                    top: top,
                                }}
                            />
                        );
                    })
                }
            </>
        );
    }

    drawPlayerCard(i, is4Players, table, player = { name: <em>Lādējas...</em>, role: `GAIDĀM`, isMove: false, }, showCards = false) {
        const cards = [
            `st-left.svg`,
            `st-middle.svg`,
            `st-right.svg`,
        ];
        const { state } = this;

        const win = [];
        if (
            player.winner &&
            player.winner.moves &&
            Array.isArray(player.winner.moves) &&
            player.winner.moves.length >= 3 &&
            player.winner.type !== `TABLE`
        ) {
            for (let i = 0; i < player.winner.moves.length; i++) {
                win.push(player.winner.moves[i].answer);
            }
        }

        const oTable = this.tables[0];

        const screen = {
            width: document.body.offsetWidth,
            height: document.body.offsetHeight,
            ratio: document.body.offsetWidth / document.body.offsetHeight,
        };

        const cs = {
            width: Math.min(screen.width * 0.9, 600) / 2 * 0.45,
            height: Math.min(screen.width * 0.9, 600) / 2 * 0.45 / 0.718,
        };


        const left = screen.width - (table.box.width + table.position.right) + ((oTable.box.left[0] - oTable.shadow.left[0]) * table.size.width / oTable.width);
        const box = [];
        box[0] = left > 0 ? left : 0;
        box[1] = box[0] + table.box.width < screen.width ? box[0] + table.box.width : screen.width;

        const style = { width: 0, padding: 0, height: 0 };

        style.width = (box[1] - box[0]) / (3 + is4Players) > 70 ? (box[1] - box[0]) / (3 + is4Players) : 70;
        if (style.width > 250) style.width = 250;

        style.height = style.width / screen.ratio * 0.8;
        if (style.width > 150) style.height = style.width / 2;
        style.padding = ((box[1] - box[0]) - (2 + is4Players) * style.width) / (3 + is4Players);

        const pLeft = box[0] + style.width * i + style.padding * (i + 1);
        const pBottom = table.box.height + table.position.bottom + oTable.getYoffset(Math.abs(table.center.left - style.width * (i + 0.5) - style.padding * (i + 1) - box[0]) / (table.size.width * 0.38)) - style.width / screen.ratio * 0.2 * (i !== 1);

        return (
            <>
                <div
                    className="player"
                    style={{
                        width: style.width,
                        height: style.height,
                        left: pLeft,
                        bottom: pBottom,
                        background: player.isMove ? `rgba(255, 255, 255, 0.36)` : undefined,
                        boxShadow: player.isMove ? `0px 0px 3px #FFFFFF` : undefined,
                    }}
                >
                    {
                        style.width / style.height >= 1.5 ? (
                            <table style={{ height: style.height, width: style.width * 0.9 }}>
                                <tr>
                                    <td rowSpan={2} style={{ minWidth: style.height * 0.75, maxWidth: style.height * 0.75, width: style.height * 0.75 }}>
                                        <img src={require('../resources/avatar.png')} alt="avatar" style={{ height: style.height * 0.8, width: `fit-content` }} />
                                    </td>
                                    <td style={{
                                        borderBottom: `1px solid #CC9F01`,
                                        verticalAlign: `baseline`,
                                        paddingTop: style.height * 0.1,
                                        lineHeight: 1.2,
                                        height: style.height * 0.4,
                                        fontSize: 0.14 * (style.width - style.height * 0.8) + 1.8,
                                        textOverflow: style.height > 100 ? `unset` : `ellipsis`,
                                        overflow: `hidden`,
                                        whiteSpace: style.height > 100 ? `unset` : `nowrap`,
                                        maxWidth: style.width - style.height * 0.8
                                    }}>
                                        {player.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ verticalAlign: `baseline`, fontSize: 0.14 * (style.width - style.height * 0.8) + 1.8, }}>{player.role}</td>
                                </tr>
                            </table>
                        ) : (
                            <div style={{
                                textAlign: `center`,
                                color: `#CC9F01`,
                                display: `flex`,
                                flexDirection: `column`,
                                justifyContent: `flex-end`,
                                height: style.height,
                            }}
                            >
                                <img
                                    style={{ height: `50%`, width: `fit-content`, marginTop: style.height * 0.1, flexGrow: 3, margin: `0 auto` }}
                                    src={require('../resources/avatar.png')}
                                    alt="avatar"
                                />
                                <div
                                    style={{
                                        width: `75%`,
                                        borderBottom: `1px solid #CC9F01`,
                                        verticalAlign: `middle`,
                                        lineHeight: 1.2,
                                        flexGrow: 1,
                                        fontSize: style.height / 4 <= 16 ? style.height / 4 : 16,
                                        margin: `0 auto`,
                                    }}
                                >
                                    {player.name}
                                </div>
                                <div
                                    style={{
                                        flexGrow: 1,
                                        verticalAlign: `baseline`,
                                        fontSize: style.height / 4 <= 15 ? style.height / 4 : 15,
                                    }}
                                >
                                    {player.role}
                                </div>
                            </div>
                        )
                    }
                </div>
                {
                    !!win.length && (
                        <div
                            style={{
                                backgroundImage: `url("${require(`../resources/card-backs/black-red/${cards[i]}`)}")`,
                                backgroundSize: `${style.width / 1.5}px ${(style.width / 1.5) * 0.6}px`,
                                width: style.width / 1.5,
                                height: (style.width / 1.5) * 0.6,
                                zIndex: 1030,
                                position: `fixed`,
                                left: pLeft + style.width / 2 - style.width / 1.5 / 2,
                                bottom: pBottom - (style.width / 1.5 / 1.3),
                                cursor: `pointer`,
                                userSelect: `none`,
                            }}
                            onClick={() => this.setState({ viewLastHand: i })}
                        />
                    )
                }
                <Modal
                    // visible
                    visible={state.viewLastHand === i}
                    zIndex={1049}
                    bodyStyle={{
                        margin: 0,
                        padding: 0,
                        display: `flex`,
                        backgroundColor: `rgba(0, 0, 0, 0)`,
                        height: 725,
                    }}
                    onOk={() => this.setState({ viewLastHand: false })}
                    onCancel={() => this.setState({ viewLastHand: false })}
                    width={Math.min(screen.width * 0.9, 600)}
                    className="table-modal"
                    closable={false}
                    destroyOnClose
                    footer={null}
                    style={{
                        bottom: table.box.height * 0.5 + (table.position.bottom - table.box.height * 0.1) + 80,
                        top: screen.height - table.box.height * 0.5 + (table.position.bottom - table.box.height * 0.1) > 300 ? 
                            screen.height - table.box.height * 0.5 + (table.position.bottom - table.box.height * 0.1) - 300 : 0,
                        left: (screen.width - Math.min(screen.width * 0.9, 600)) / 2,
                        right: (screen.width - Math.min(screen.width * 0.9, 600)) / 2,
                    }}
                >
                    {
                        win.map(card => (
                            <div
                                style={{
                                    position: `relative`,
                                    flexGrow: 1,
                                    margin: 0,
                                    padding: 0,
                                    userSelect: `none`,
                                    width: `100%`,
                                    height: `100%`,
                                    minHeight: (screen.height - (table.box.height * 0.5 + table.position.bottom - table.box.height * 0.1 + 10) - table.box.height * 0.15) * 0.35,
                                }}
                            >
                                <div
                                    className="playing-card"
                                    style={{
                                        backgroundImage: `url("${require(`../resources/cards/basic/${card}.svg`)}")`,
                                        backgroundSize: `${cs.width}px ${cs.height}px`,
                                        height: cs.height,
                                        width: cs.width,
                                        position: `absolute`,
                                        top: `50%`,
                                        left: `50%`,
                                        transform: `translate(-50%, -50%)`,
                                    }}
                                />
                            </div>
                        ))
                    }
                </Modal>
            </>
        );
    }
}

export default Play;
