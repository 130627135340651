import React, { Component } from 'react';
import { Button, Spin, Form, Input, Checkbox, Typography, Row, Col, notification, message } from 'antd';

import { LoadingOutlined, GooglePlusOutlined, FacebookOutlined } from '@ant-design/icons';

import logo from "../resources/logo.png"
import { required, STATE } from '../engine/client';

const { Link } = Typography;

const eaqulPassword = ({ getFieldValue }) => ({
    validator(_, value) {
        if (!value || getFieldValue(`password-1`) === value) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(`Parolēm jāsakrīt`));
    },
});

const isValidPassword = ({ getFieldValue }) => ({
    validator(_, value) {
        if (!value) {
            return Promise.resolve();
        } else if (value.length < 9) {
            return Promise.reject(new Error(`Parolei jābūt vismaz 9 rakstzīmes garai.`));
        } else if (value.match(/[A-Z]/) === null) {
            return Promise.reject(new Error(`Parolei jāsatur vismaz viens lielais latīņu alfabēta burts.`));
        } else if (value.match(/[a-z]/) === null) {
            return Promise.reject(new Error(`Parolei jāsatur vismaz viens mazais latīņu alfabēta burts.`));
        } else if (value.match(/[^A-Za-z]/) === null) {
            return Promise.reject(new Error(`Parolei jāsatur vismaz viens cipars vai speciāls simbols.`));
        }
        return Promise.resolve();
    },
});

class Login extends Component {
    constructor(props) {
        super(props);

        this.key = null;
        this.state = {
            authState: null,
        };
    }

    authorize = async (data) => {
        this.setState({ authState: `loading` });

        await this.props.client.user.login(data.email, data.password, data.remember);
        // if (result?.ok) {
        //     this.props.client.setState( STATE.AUTH_SUCCESS );
        // } else {
        //     this.props.client.setState( STATE.AUTH_FAILED );
        // }

        this.props.base.forceUpdate();

        this.setState({ authState: `login` });
    };

    finishRegistration = async (data) => {
        this.setState({ authState: `loading` });

        const response = await this.props.client.post(`set-password`, { ...data, key: this.key }, undefined, `auth`);

        if (response.ok) {
            message.success(response.body?.message ?? `Parole iestatīta.`);
        } else {
            notification.error({
                duration: 60,
                message: response.body.title ?? `Mēģiniet vēlreiz!`,
                description: response.body.error ?? `Kaut kas nogāja greizi - neizdevās iestatīt paroli.`,
            });
        }

        window.location.pathname = `/league`;
    };

    register = async (data) => {
        this.setState({ authState: `loading` });

        await this.props.client.user.register(data.name, data.surname, data.email);
        this.props.base.forceUpdate();

        this.setState({ authState: `login` });
    };

    checkRegistartionKey = async (key) => {
        const response = await this.props.client.post(`check-registration-key`, { key }, undefined, `auth`);

        let isValid = false;
        if (response.ok) {
            isValid = response.body?.valid ?? false;
        }

        if (isValid) {
            this.setState({ authState: `finish-registration` });
        } else {
            this.setState({ authState: null });
            notification.error({
                duration: 0,
                message: `Links ir novecojis vai neeksistē.`,
            });
        }
    };

    render() {
        let { authState } = this.state;

        const screen = {
            width: document.body.offsetWidth,
            height: document.body.offsetHeight,
            ratio: document.body.offsetWidth / document.body.offsetHeight,
        };

        if (!this.key) {
            let finishRegMatch = /\/finish-registration\/([a-zA-Z0-9]+)\/?/.exec(window.location.pathname);
            if (finishRegMatch && finishRegMatch[1].length) {
                authState = `loading`;
                this.key = finishRegMatch[1];
                this.checkRegistartionKey(this.key);
            }
        }

        switch (authState) {
            case `loading`:
                return (
                    <>
                        <img
                            src={logo}
                            alt="Zolmaniem"
                            style={{
                                position: `relative`,
                                height: 100,
                                width: `fit-content`,
                                margin: `15px auto`,
                                display: `block`,
                                maxWidth: `100%`,
                            }}
                        />
                        <Spin
                            size="large"
                            indicator={<LoadingOutlined style={{ fontSize: 86, color: `#001529` }} spin />}
                            style={{
                                top: `50%`,
                                left: `50%`,
                                position: `absolute`,
                                transform: `translate(-50%, -50%)`,
                            }}
                        />
                    </>
                );


            case `login`:
                return (
                    <div style={{
                        height: `100%`,
                        width: screen.width > 500 ? `75%` : `100%`,
                        background: `white`,
                        margin: `0 auto`,
                    }}>
                        <img
                            src={logo}
                            alt="Zolmaniem"
                            style={{
                                position: `relative`,
                                height: 100,
                                width: `fit-content`,
                                margin: `15px auto`,
                                display: `block`,
                                maxWidth: `100%`,
                            }}
                        />
                        <div className="top" style={{ height: 100, position: `relative` }}>
                            <h2 className="auth-title">AUTORIZĀCIJA</h2>
                            <Link onClick={() => this.setState({ authState: null })} style={{position: `absolute`, left: 0, bottom: 20 }}>Atpakaļ</Link>
                        </div>
                        <Form onFinish={this.authorize} initialValues={{ remember: true }}>
                            <Form.Item name="email" rules={[required]}>
                                <Input placeholder="Norādiet e-pastu" />
                            </Form.Item>
                            <Form.Item name="password" rules={[required]}>
                                <Input.Password placeholder="Norādiet paroli" />
                            </Form.Item>
                            <Row style={{ marginTop: 20 }}>
                                <Col span={24} sm={12}>
                                    <Form.Item name="remember" valuePropName="checked" style={{ textAlign: `left` }}>
                                        <Checkbox><span>Atcerēties mani</span></Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={0} sm={12}>
                                    <Link
                                        style={{ fontSize: `1.25em`, margin: `10px 0`, float: `right` }}
                                        onClick={() => this.setState({ authState: `forgot-password` })}
                                    >
                                        Aizmirsi paroli?
                                    </Link>
                                </Col>
                            </Row>
                            <Button type="primary" htmlType="submit" style={{ width: `100%`, minHeight: 40, fontSize: `1.2em` }} className="auth-btn">IENĀKT</Button>
                            <Row>
                                <Col span={24} sm={0} style={{ margin: `10px 0 0 0` }}>
                                    <Link
                                        style={{ fontSize: `1.25em` }}
                                        onClick={() => this.setState({ authState: `forgot-password` })}
                                    >
                                        Aizmirsi paroli?
                                    </Link>
                                </Col>
                            </Row>
                            <Link
                                style={{ fontSize: `1.25em`, margin: `10px 0`, display: `block`, textAlign: `center` }}
                                onClick={() => this.setState({ authState: `register` })}
                            >
                                Neesi reģistrējies? Spied šeit!
                            </Link>
                        </Form>
                    </div>
                );

            case `reset-password`:
                return (
                    <div style={{
                        height: `100%`,
                        width: screen.width > 500 ? `75%` : `100%`,
                        background: `white`,
                        margin: `0 auto`,
                    }}>
                        <img
                            src={logo}
                            alt="Zolmaniem"
                            style={{
                                position: `relative`,
                                height: 100,
                                width: `fit-content`,
                                margin: `15px auto`,
                                display: `block`,
                                maxWidth: `100%`,
                            }}
                        />
                        <div className="top" style={{ height: 100, position: `relative` }}>
                            <h2 className="auth-title">ATJAUNO PAROLI</h2>
                        </div>
                        <Form onFinish={(data) => console.log(data)}>
                            <Form.Item
                                name="password-1"
                                rules={[required, isValidPassword]}
                            >
                                <Input.Password placeholder="Norādiet paroli" />
                            </Form.Item>
                            <Form.Item
                                name="password-2"
                                rules={[required, eaqulPassword]}
                            >
                                <Input.Password placeholder="Atkārtojiet paroli" />
                            </Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="auth-btn"
                                style={{ width: `100%`, minHeight: 40, fontSize: `1.2em` }}
                            >
                                SAGLABĀT
                            </Button>
                        </Form>
                    </div>
                );

            case `finish-registration`:
                return (
                    <div style={{
                        height: `100%`,
                        width: screen.width > 500 ? `75%` : `100%`,
                        background: `white`,
                        margin: `0 auto`,
                    }}>
                        <img
                            src={logo}
                            alt="Zolmaniem"
                            style={{
                                position: `relative`,
                                height: 100,
                                width: `fit-content`,
                                margin: `15px auto`,
                                display: `block`,
                                maxWidth: `100%`,
                            }}
                        />
                        <div className="top" style={{ height: 100, position: `relative` }}>
                            <h2 className="auth-title">PABEIDZ REĢISTRĀCIJU</h2>
                        </div>
                        <Form onFinish={this.finishRegistration}>
                            <Form.Item
                                name="password-1"
                                rules={[required, isValidPassword]}
                            >
                                <Input.Password placeholder="Norādiet paroli" />
                            </Form.Item>
                            <Form.Item
                                name="password-2"
                                rules={[required, eaqulPassword]}
                            >
                                <Input.Password placeholder="Atkārtojiet paroli" />
                            </Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="auth-btn"
                                style={{ width: `100%`, minHeight: 40, fontSize: `1.2em` }}
                            >
                                PABEIGT
                            </Button>
                        </Form>
                    </div>
                );

            case `forgot-password`:
                return (
                    <>
                        <div style={{
                            height: `100%`,
                            width: screen.width > 500 ? `75%` : `100%`,
                            background: `white`,
                            margin: `0 auto`,
                        }}>
                            <img
                                src={logo}
                                alt="Zolmaniem"
                                style={{
                                    position: `relative`,
                                    height: 100,
                                    width: `fit-content`,
                                    margin: `15px auto`,
                                    display: `block`,
                                    maxWidth: `100%`,
                                }}
                            />
                            <div className="top" style={{ height: 100, position: `relative` }}>
                                <h2 className="auth-title">AIZMIRSI PAROLI?</h2>
                                <Link onClick={() => this.setState({ authState: `login` })} style={{position: `absolute`, left: 0, bottom: 20 }}>Atpakaļ</Link>
                            </div>
                            <Form onFinish={(data) => console.log(data)}>
                                <Form.Item
                                    name="email"
                                    rules={[required]}
                                >
                                    <Input placeholder="Norādiet e-pastu" />
                                </Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="auth-btn"
                                    style={{ width: `100%`, minHeight: 40, fontSize: `1.2em` }}
                                >
                                    NOSŪTĪT E-PASTU
                                </Button>
                            </Form>
                        </div>
                    </>
                );

            case `register`:
                return (
                    <div style={{
                        height: `100%`,
                        width: screen.width > 500 ? `75%` : `100%`,
                        background: `white`,
                        margin: `0 auto`,
                    }}>
                        <img
                            src={logo}
                            alt="Zolmaniem"
                            style={{
                                position: `relative`,
                                height: 100,
                                width: `fit-content`,
                                margin: `15px auto`,
                                display: `block`,
                                maxWidth: `100%`,
                            }}
                        />
                        <div className="top" style={{ height: 100, position: `relative` }}>
                            <h2 className="auth-title">REĢISTRĒTIES</h2>
                            <Link onClick={() => this.setState({ authState: `login` })} style={{position: `absolute`, left: 0, bottom: 20 }}>Atpakaļ</Link>
                        </div>
                        <Form layout="vertical" onFinish={this.register}>
                            <Form.Item
                                name="name"
                                rules={[required]}
                            >
                                <Input placeholder="Norādiet vārdu" />
                            </Form.Item>
                            <Form.Item
                                name="surname"
                                rules={[required]}
                            >
                                <Input placeholder="Norādiet uzvārdu" />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                rules={[required]}
                            >
                                <Input placeholder="Norādiet e-pastu" />
                            </Form.Item>
                            <Form.Item
                                name="agreed"
                                valuePropName="checked"
                                style={{ textAlign: `left` }}
                            >
                                <Checkbox>
                                    <span>
                                        Piekrītu <Link onClick={() => window.open(`${document.location.origin}/files/PRIVATUMA_NOTEIKUMI`)}>Privātuma noteikumiem</Link> un <Link onClick={() => window.open(`${document.location.origin}/files/LIETOSANAS_NOTEIKUMI`)}>Lietošanas noteikumiem</Link>.
                                    </span>
                                </Checkbox>
                            </Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="auth-btn"
                                style={{ width: `100%`, minHeight: 40, fontSize: `1.2em` }}
                            >
                                REĢISTRĒTIES
                            </Button>
                        </Form>
                    </div>
                );

            case `social`:
                return (
                    <div style={{
                        position: `absolute`,
                        top: 0,
                        bottom: 0,
                        left: `5%`,
                        right: `5%`,
                        margin: `20px auto`,
                        display: `flex`,
                        flexDirection: `column`,
                        justifyContent: `space-between`,
                        background: `white`,
                        gap: 10,
                    }}>
                        <img
                            src={logo}
                            alt="Zolmaniem"
                            style={{
                                position: `relative`,
                                height: 100,
                                width: `fit-content`,
                                margin: `15px auto`,
                                maxWidth: `100%`,
                                display: `block`,
                            }}
                        />
                        <Button
                            type="primary"
                            className="google"
                            style={{ fontSize: screen.width > 500 ? `1.5em` : `1.2em` }}
                        >
                            <GooglePlusOutlined /> Google
                        </Button>
                        <Button
                            type="primary"
                            className="facebook"
                            style={{ fontSize: screen.width > 500 ? `1.5em` : `1.2em` }}
                        >
                            <FacebookOutlined /> Facebook
                        </Button>
                        <Button
                            type="primary"
                            className="inbox"
                            style={{ fontSize: screen.width > 500 ? `1.5em` : `1.2em` }}
                        >
                            <span style={{ color: `#8c847c` }}>inbox</span><span style={{ color: `#cc0414` }}>.lv</span>
                        </Button>
                    </div>
                );

            default:
                return (
                    <div style={{
                        position: `absolute`,
                        top: 0,
                        bottom: 0,
                        left: `5%`,
                        right: `5%`,
                        margin: `20px auto`,
                        display: `flex`,
                        flexDirection: `column`,
                        justifyContent: `space-between`,
                        background: `white`,
                        gap: 10,
                    }}>
                        <img
                            src={logo}
                            alt="Zolmaniem"
                            style={{
                                position: `relative`,
                                height: 100,
                                width: `fit-content`,
                                margin: `15px auto`,
                                display: `block`,
                                maxWidth: `100%`,
                            }}
                        />
                        <Button
                            type="primary"
                            className="auth-btn"
                            style={{ fontSize: screen.width > 500 ? `1.5em` : `1.2em` }}
                            onClick={() => this.setState({ authState: `login` })}
                        >
                            PIESLĒGTIES
                        </Button>
                        <Button
                            type="primary"
                            className="auth-btn"
                            style={{ fontSize: screen.width > 500 ? `1.5em` : `1.2em` }}
                            onClick={() => this.setState({ authState: `social` })}
                        >
                            IZMANTOJOT SOCIĀLOS TĪKLUS
                        </Button>
                        <Button
                            type="primary"
                            className="auth-btn"
                            style={{ fontSize: screen.width > 500 ? `1.5em` : `1.2em` }}
                            onClick={() => this.setState({ authState: `loading` })}
                        >
                            TURPINĀT KĀ VIESIS
                        </Button>
                    </div>
                );
        }
    }
}

export default Login;
