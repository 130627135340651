import { Carousel, Radio, Space, Typography } from 'antd';
import React from 'react';
import Layout from '../layout';
import Points from '../layout/widgets/Points';

import accountBigYellow from '../resources/accountBigYellow.svg';
import accountBig from '../resources/accountBig.svg';
import moneyYellow from '../resources/moneyYellow.svg';
import money from '../resources/money.svg';
import twoCards from '../resources/twoCards.svg';
import club from '../resources/club.svg';

import ReactGA from 'react-ga';

class Shop extends Layout {
    constructor(props) {
        super(props);

        this.state = {
            offers: [
                {
                    key: 1,
                    icon: true,
                    title: `Piedāvājums`,
                    info: `Iegādājies 360’000 ZD par 3.00€`,
                    label: `PAPILDINĀT`,
                    paymentOptions: [
                        {
                            key: `card`,
                            label: `Maksājumu karte`,
                        },
                        {
                            key: `bank`,
                            label: `Internetbanka`,
                        },
                    ]
                },
                {
                    key: 2,
                    icon: true,
                    title: `Piedāvājums`,
                    info: `Iegādājies 1’566’000 ZD par 9.99€ (30% atlaide!)*`,
                    label: `PAPILDINĀT`,
                    paymentOptions: [
                        {
                            key: `card`,
                            label: `Maksājumu karte`,
                        },
                        {
                            key: `bank`,
                            label: `Internetbanka`,
                        },
                    ]
                },
                {
                    key: 3,
                    icon: true,
                    title: `Piedāvājums`,
                    info: `Iegādājies 3’420’000 ZD par 18.99€ (50% atlaide!)*`,
                    label: `PAPILDINĀT`,
                    paymentOptions: [
                        {
                            key: `card`,
                            label: `Maksājumu karte`,
                        },
                        {
                            key: `bank`,
                            label: `Internetbanka`,
                        },
                    ]
                },
                {
                    key: 4,
                    icon: false,
                    title: `Abonements`,
                    info: `Iegādājies abonementu par 0.99€ mēnesī vai 9.99€ gadā.`,
                    label: `ABONĒT`,
                    paymentOptions: [
                        {
                            key: `card`,
                            label: `Maksājumu karte`,
                        },
                        {
                            key: `bank`,
                            label: `Internetbanka`,
                        },
                    ]
                },
                {
                    key: 5,
                    icon: false,
                    title: `Punktu dzēšana`,
                    info: `Punktu dzēšana (punktu nodzēšana līdz 0) par 3.00€`,
                    label: `IZMANTOT`,
                    paymentOptions: [
                        {
                            key: `card`,
                            label: `Maksājumu karte`,
                        },
                        {
                            key: `bank`,
                            label: `Internetbanka`,
                        },
                    ]
                },
            ],
            selections: {},
        };
    }

    content() {
        const { size } = this.props;
        if (size > 1380) {
            return this.contentWide();
        }

        return this.contentMobile();
    }

    head() {
        const { user } = this.props.client;
        
        return (
            <>
                <Typography.Title
                    level={2}
                    style={{
                        color: `#F3DD8E`,
                        fontSize: 36,
                        fontFamily: `Montserrat`,
                        margin: `60px auto -60px auto`,
                        textTransform: `uppercase`,
                        fontWeight: 600,
                        textAlign: `center`,
                    }}
                >
                    Papildināt ZD
                </Typography.Title>
                <div style={{ position: `relative`, margin: `150px 0 -150px 0`, textAlign: `center` }}>
                    <img
                        src={accountBigYellow}
                        alt="account-big-ylw"
                        style={{
                            height: 105,
                            width: 90,
                            textAlign: `center`
                        }}
                    />
                    <img
                        src={moneyYellow}
                        alt="money-ylw"
                        style={{
                            position: `absolute`,
                            height: 60,
                            width: 70,
                            top: 90,
                            left: `calc(50% - 95px)`,
                        }}
                    />
                    <span
                        style={{
                            position: `absolute`,
                            fontSize: 20,
                            color: `#550187`,
                            top: 116,
                            left: `calc(50% - 58px)`,
                        }}
                    >
                        ZD
                    </span>
                    <Points
                        points={user.zd ?? 0}
                        style={{
                            color: `#F3DD8E`,
                            marginLeft: 45,
                            marginTop: 5,
                            fontSize: 26,
                            display: `block`,
                        }}
                    />
                </div>
            </>
        );
    }

    contentMobile() {
        return (
            <>    
                {
                    this.props.size > 620 ? this.offersFlex(this.state.offers) : this.offersCarousel(this.state.offers)
                }
            </>
        );
    }

    contentWide() {
        const { user } = this.props.client;

        return (
            <>
                <table>
                    <tr>
                        <td style={{ width: 150, textAlign: `center`, }}>
                            <img
                                src={accountBig}
                                alt="account-big"
                                style={{
                                    height: 62,
                                    width: 54,
                                }}
                            />
                        </td>
                        <td>
                            <Typography.Title
                                level={2}
                                style={{
                                    color: `#50027E`,
                                    fontSize: 40,
                                    fontFamily: `Montserrat`,
                                    margin: `35px 0 0 0`,
                                    textTransform: `uppercase`,
                                    fontWeight: 600,
                                }}
                            >
                                Papildināt ZD
                            </Typography.Title>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: 150, position: `relative` }}>
                            <img
                                src={money}
                                alt="money"
                                style={{
                                    position: `absolute`,
                                    height: 42,
                                    width: 37,
                                    top: -20,
                                    left: 20,
                                }}
                            />
                            <Points
                                points={user.zd ?? 0}
                                style={{
                                    color: `#606060`,
                                    marginLeft: 60,
                                    marginTop: -20,
                                    fontSize: 15,
                                    display: `block`,
                                }}
                            />
                        </td>
                        <td>
                            <span
                                style={{
                                    color: `#606060`,
                                    fontSize: 18,
                                    fontFamily: `Roboto`,
                                    fontWeight: `bold`,
                                    margin: `-12px 0 12px 0`,
                                    display: `block`,
                                }}
                            >
                                Iegādājies ZD līdz 50% izdevīgāk norēķinoties ar maksājumu karti vai caur internetbanku:
                            </span>
                        </td>
                    </tr>
                </table>
                {
                    this.offersFlex(this.state.offers)
                }
                <span
                    style={{
                        color: `#606060`,
                        fontSize: 16,
                        fontFamily: `Roboto`,
                        display: `block`,
                        margin: `15px 30px`, 
                    }}
                >
                    * - Atlaide jau iekļauta cenā un norādīta salīdzinot ar ZD iegādi par 3.00€.
                </span>
            </>
        );
    }

    offer = (offer, flex = true) => {
        return (
            <div
                style={{
                    minHeight: 270,
                    minWidth: 270,
                    maxWidth: 270,
                    margin: `10px ${flex ? `0` : `auto`}`,
                    padding: 0,
                    borderRadius: 6,
                    boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
                    background: `white`,
                }}
            >
                <div
                    style={{
                        margin: 0,
                        width: `100%`,
                        height: 70,
                        background: `#8A30BF`,
                        position: `relative`,
                        borderRadius: `6px 6px 0 0`,
                    }}
                >
                    {
                        !!offer.icon && (
                            <>
                                <img
                                    src={twoCards}
                                    alt="two-cards"
                                    style={{
                                        position: `absolute`,
                                        height: 60,
                                        width: 60,
                                        top: 4,
                                        left: 5,
                                    }}
                                />
                                <img
                                    src={club}
                                    alt="club"
                                    style={{
                                        position: `absolute`,
                                        height: 12,
                                        width: 12,
                                        top: 20,
                                        left: 10,
                                    }}
                                />
                                <span
                                    style={{
                                        fontSize: 30,
                                        position: `absolute`,
                                        fontFamily: `Montserrat`,
                                        color: `#E2C354`,
                                        top: 6,
                                        left: 39,
                                    }}
                                >
                                    {offer.key}
                                </span>
                            </>
                        )
                    }
                    <span
                        style={{
                            textTransform: `uppercase`,
                            fontFamily: `Montserrat`,
                            fontWeight: 600,
                            fontSize: 24,
                            color: `#E2C354`,
                            marginLeft: offer.icon ? 75 : 20,
                            lineHeight: `70px`,
                        }}
                    >
                        {offer.title}
                    </span>
                </div>
                <div
                    style={{
                        fontFamily: `Roboto`,
                        fontWeight: 600,
                        fontSize: 16,
                        color: `#606060`,
                        padding: `20px`,
                        width: `100%`,
                    }}
                >
                    {offer.info}
                </div>
                {
                    !!Array.isArray(offer.paymentOptions) && (
                        <Radio.Group
                            onChange={(e) => {                                                
                                this.setState((old) => ({ selections: { ...old.selections, [offer.key]: e.target.value } }));
                            }}
                            value={this.state.selections[offer.key]}
                        >
                            <Space direction="vertical" style={{ marginLeft: 15 }}>
                                {
                                    offer.paymentOptions.map(opt => (
                                        <Radio value={opt.key}>
                                            <span
                                                style={{
                                                    color: `#606060`,
                                                    fontFamily: `Roboto`,
                                                    fontSize: 16,
                                                }}
                                            >
                                                {opt.label}
                                            </span>
                                        </Radio>
                                    ))
                                }
                            </Space>
                        </Radio.Group>
                    )
                }
                <div
                    className="button"
                    style={{
                        width: `fit-content`,
                        margin: `15px`,
                    }}
                    onClick={() => {
                        ReactGA.event({
                            category: offer.title,
                            action: offer.info,
                            label: offer.label,
                        });
                    }}
                >
                    {offer.label}
                </div>
            </div>
        );
    };

    offersFlex = (offers) => {
        if (Array.isArray(offers)) {
            return (
                <div
                    style={{
                        display: `flex`,
                        flexWrap: `wrap`,
                        width: `100%`,
                        minHeight: 250,
                        justifyContent: `space-evenly`,
                        gap: `5px`,
                    }}
                >
                    {
                        offers.map((offer) => this.offer(offer))
                    }
                </div>
            );
        }

        return <></>;
    }

    offersCarousel = (offers) => {
        if (Array.isArray(offers)) {
            return (
                <Carousel
                    autoplay
                    dots={false}
                    style={{
                        width: `100%`,
                        minHeight: 250,
                    }}
                >
                    {
                        offers.map((offer) => <div>{this.offer(offer, false)}</div>)
                    }
                </Carousel>
            );
        }

        return <></>;
    }
}

export default Shop;
