import React from 'react';
import Layout from '../../layout';
import { Form, Input, Typography } from 'antd';
import DataTable from '../../layout/widgets/DataTable';
import moment from 'moment';
import { Editor } from '@tinymce/tinymce-react';

class Footer extends Layout {
    mobileHeadVh = 15;
    mobileHeadHeight = 150;

    constructor(props) {
        super(props);

        this.ready = false;

        this.state = {
            types: {},
            data: [],
            ctx: 1,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        if (this.props.client) {
            const response = await this.props.client.get( `admin/contentTypes`, 1, 100 );
            if (response.ok && response.status === 200) {
                this.setState({ types: { ...response.body.types } });
            }
        }
    }

    content() {
        const { size } = this.props;
        if (size > 1380) {
            return this.contentWide();
        }

        return this.contentMobile();
    }

    head() {
        return (
            <div style={{
                position: `absolute`,
                height: `max(25vh, 300px)`,
                top: 0,
                left: 0,
                right: 0,
            }}>
                <Typography.Title
                    level={2}
                    style={{
                        color: `#F3DD8E`,
                        fontSize: 36,
                        margin: `4vh 0 30px 0`,
                        textAlign: `center`
                    }}
                >
                    Saturs
                </Typography.Title>
            </div>
        );
    }

    contentMobile() {
        // const { user } = this.props.client;

        const formJsx = (
            <>
                <Form.Item name="key">
                    <Input disabled />
                </Form.Item>
                <Form.Item trigger="onEditorChange" rules={[{ required: true, message: `Ievadiet tekstu` }]} name="data">
                <Editor
                    apiKey="euipz64afb3zknqct64z4cr8asro9gz0ojcwb9skszyw8k3f"
                    init={{
                        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss',
                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                        tinycomments_mode: 'embedded',
                        tinycomments_author: 'Author name',
                        mergetags_list: [
                            { value: 'First.Name', title: 'First Name' },
                            { value: 'Email', title: 'Email' },
                        ],
                        ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                    }}
                    // initialValue="Welcome to TinyMCE!"
                />
                </Form.Item>
            </>
        );
        
        return (
            <>
                <div style={{ height: 50, width: `100%` }} />
                <DataTable
                    key={`content-${this.state.ctx}`}
                    columns={[
                        {
                            title: `Nosaukums`,
                            key: `key`,
                        },
                        {
                            title: `Datums`,
                            key: `created_at`,
                            render: (val) => {
                                return moment(val).format(`DD.MM.YYYY HH:mm`);
                            },
                        },
                    ]}
                    client={this.props.client}
                    source="admin/content"
                    onAdd={() => {
                        this.setState((old) => ({ ctx: old.ctx + 1 }));
                    }}
                    addForm={null}
                    editForm={formJsx}
                    modalStyle={{ minWidth: 650 }}
                />
            </>
        );
    }

    contentWide() {
        // const { user } = this.props.client;

        const formJsx = (
            <>
                <Form.Item name="key">
                    <Input disabled />
                </Form.Item>
                <Form.Item trigger="onEditorChange" rules={[{ required: true, message: `Ievadiet tekstu` }]} name="data">
                    <Editor
                        apiKey="euipz64afb3zknqct64z4cr8asro9gz0ojcwb9skszyw8k3f"
                        init={{
                            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss',
                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                            tinycomments_mode: 'embedded',
                            tinycomments_author: 'Author name',
                            mergetags_list: [
                                { value: 'First.Name', title: 'First Name' },
                                { value: 'Email', title: 'Email' },
                            ],
                            ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                        }}
                        // initialValue="Welcome to TinyMCE!"
                    />
                </Form.Item>
            </>
        );
        
        return (
            <>
                <Typography.Title level={2}>Saturs</Typography.Title>
                <DataTable
                    key={`content-${this.state.ctx}`}
                    columns={[
                        {
                            title: `Nosaukums`,
                            key: `key`,
                        },
                        {
                            title: `Datums`,
                            key: `created_at`,
                            render: (val) => {
                                return moment(val).format(`DD.MM.YYYY HH:mm`);
                            },
                        },
                    ]}
                    client={this.props.client}
                    source="admin/content"
                    onAdd={() => {
                        this.setState((old) => ({ ctx: old.ctx + 1 }));
                    }}
                    addForm={null}
                    editForm={formJsx}
                    modalStyle={{ minWidth: 650 }}
                />
            </>
        );
    }
}

export default Footer;
