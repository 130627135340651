import React from 'react';
import Layout from '../../layout';
import { Typography } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';

class Politics extends Layout {
    mobileHeadVh = 15;
    mobileHeadHeight = 150;

    constructor(props) {
        super(props);

        this.ready = false;
        this.links = [
            {
                key: `website-rules`,
                href: `files/LIETOSANAS_NOTEIKUMI`,
                title: `Mājas lapas lietošanas noteikumi`,
            },
            {
                key: `privacy`,
                href: `files/PRIVATUMA_NOTEIKUMI`,
                title: `Privātuma noteikumi`,
            },
            {
                key: `cookies`,
                href: `files/SIKDATNU_NOTEIKUMI`,
                title: `Sīkdatņu noteikumi`,
            },
            {
                key: `data-process`,
                href: `files/DATU_APSTRADES_NOTEIKUMI`,
                title: `Datu apstrādes noteikumi`,
            },
            {
                key: `distance`,
                href: `files/DISTANCES_LIGUMS`,
                title: `Distances līgums`,
                sub: [
                    {
                        key: `distance`,
                        href: `files/ATTEIKUMA_VEIDLAPA`,
                        title: `Atteikuma veidlapa`,
                    }
                ],
            },
            {
                key: `subscription`,
                href: `files/REKLAMAS_NOTEIKUMI`,
                title: `Reklāmas noteikumi`,
            },
        ];
    }

    // componentDidMount() {
    // }

    content() {
        const { size } = this.props;
        if (size > 1380) {
            return this.contentWide();
        }

        return this.contentMobile();
    }

    head() {
        return (
            <div style={{
                position: `absolute`,
                height: `max(25vh, 300px)`,
                top: 0,
                left: 0,
                right: 0,
            }}>
                <Typography.Title
                    level={2}
                    style={{
                        color: `#F3DD8E`,
                        fontSize: 36,
                        margin: `4vh 0 30px 0`,
                        textAlign: `center`
                    }}
                >
                    Noteikumi
                </Typography.Title>
            </div>
        );
    }

    contentMobile() {
        // const { user } = this.props.client;

        return (
            <>
                <div style={{ height: 50, width: `100%` }} />
                {
                    this.links.map((item) => (
                        <>
                            <Typography.Link
                                key={item.key}
                                onClick={() => window.open(`${document.location.origin}/${item.href}`)}
                                style={{
                                    cursor: `alias`,
                                    textTransform: `uppercase`,
                                    margin: `5px 0`,
                                    display: `inline-block`,
                                    marginLeft: 40,
                                }}
                            >
                                {item.title}
                                <FilePdfOutlined className="pdf-icon" />
                            </Typography.Link>
                            <br />
                            {
                                !!item.sub && item.sub.map((sub) => (
                                    <>
                                        <div
                                            style={{
                                                display: `inline-block`,
                                                marginLeft: 45,
                                                width: 10,
                                                height: 20,
                                                borderBottom: `1px solid #1890ff`,
                                                borderLeft: `1px solid #1890ff`,
                                            }}
                                        />
                                        <Typography.Link
                                            key={sub.key}
                                            onClick={() => window.open(`${document.location.origin}/${sub.href}`)}
                                            style={{
                                                cursor: `alias`,
                                                textTransform: `uppercase`,
                                                margin: `5px 0`,
                                                display: `inline-block`,
                                                marginLeft: 10,
                                            }}
                                        >
                                            {sub.title}
                                            <FilePdfOutlined className="pdf-icon" />
                                        </Typography.Link>
                                        <br />
                                    </>
                                ))
                            }
                        </>
                    ))
                }
            </>
        );
    }

    contentWide() {
        // const { user } = this.props.client;

        return (
            <>
                <Typography.Title level={2}>Noteikumi</Typography.Title>
                {
                    this.links.map((item) => (
                        <>
                            <Typography.Link
                                key={item.key}
                                onClick={() => window.open(`${document.location.origin}/${item.href}`)}
                                style={{
                                    cursor: `alias`,
                                    textTransform: `uppercase`,
                                    margin: `5px 0`,
                                    display: `inline-block`,
                                    marginLeft: 40,
                                }}
                            >
                                {item.title}
                                <FilePdfOutlined className="pdf-icon" />
                            </Typography.Link>
                            <br />
                            {
                                !!item.sub && item.sub.map((sub) => (
                                    <>
                                        <div
                                            style={{
                                                display: `inline-block`,
                                                marginLeft: 45,
                                                width: 10,
                                                height: 20,
                                                borderBottom: `1px solid #1890ff`,
                                                borderLeft: `1px solid #1890ff`,
                                            }}
                                        />
                                        <Typography.Link
                                            key={sub.key}
                                            onClick={() => window.open(`${document.location.origin}/${sub.href}`)}
                                            style={{
                                                cursor: `alias`,
                                                textTransform: `uppercase`,
                                                margin: `5px 0`,
                                                display: `inline-block`,
                                                marginLeft: 10,
                                            }}
                                        >
                                            {sub.title}
                                            <FilePdfOutlined className="pdf-icon" />
                                        </Typography.Link>
                                        <br />
                                    </>
                                ))
                            }
                        </>
                    ))
                }
            </>
        );
    }
}

export default Politics;
