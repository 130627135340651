import React, { Component } from 'react';

import './App.css';
import './design.css';

import Client, { STATE } from './engine/client';
import { Modal, notification } from 'antd';
import Login from './layout/Login';

import PageNotFound from './layout/PageNotFound';
import routes from './engine/routes';
import Cookies from "js-cookie";

import ReactGA from 'react-ga';
class App extends Component {
	client = null;

	constructor(props) {
		super(props);

		this.title = `Zolmaņiem | Home`;
		this.path = window.location.pathname;

		this.state = {
			size: document.body.offsetWidth,
		}
		
		this.client = new Client(true);
		this.client.setState(STATE.LOADING);
		ReactGA.initialize('G-X3G3RH7ZRY');
	}

	navigate = (url, title = null) => {
		document.title = title ?? this.title;
		window.history.pushState({ urlPath: url }, title ?? this.title, url);
		this.path = window.location.pathname;
		this.forceUpdate();

		ReactGA.pageview(this.path + window.location.search);
	}

  	componentDidMount() {
		window._navigate = (url, title = null) => this.navigate(url, title);

		window.onpopstate = () => {
			this.path = window.location.pathname;
			this.forceUpdate();
		};

		window.onresize = () => {
			this.setState({ size: document.body.offsetWidth });
		};

    	document.title = `Zolmaniem`;
    
		this.client.on(STATE.AUTH_SUCCESS, () => {
			this.client.setState(STATE.WEBSOCK_CONNECTING);
			this.client.ws.connect(
				true,
				() => {
					this.client.setState( STATE.WEBSOCK_CONNECTED );
				},
				(state) => {
					console.log(state?.toUpperCase());
					this.client.setState( STATE[ `WEBSOCK_${state?.toUpperCase()} `] );
				}
			);
			this.refresh();
		});

		this.client.on(STATE.AUTH_FAILED, () => {
			if ( this.client?.user?.token ) {
				Cookies.remove( `Authorization` );
				this.client.user.token = null;
				notification.error({
				duration: 60,
				message: `Tava sesija ir beigusies`,
				description: `Lūdzu autorizējies vēlreiz.`,
				});
			} else {
				notification.error({
				duration: 60,
				message: `Autorizācija neizdevās.`,
				description: `Lūdzu autorizējies vēlreiz.`,
				});
			}
			this.forceUpdate();
			// console.log( `Detected client state change: ${ state }` );
		});

		this.client.on(STATE.WEBSOCK_FAILED, ( state ) => {
			console.log( `Detected client state change: ${ state }` );
		});
		
		this.client.on(STATE.WEBSOCK_UNAVAILABLE, ( state ) => {
			console.log( `Detected client state change: ${ state }` );
		});
		
		this.client.on(STATE.WEBSOCK_CONNECTED, async ( state ) => {
			this.client.setState(STATE.WEBSOCK_AUTH);
			const res = await this.client.get( `ws`, null, null, [{ key: `socket`, value: this.client.ws.echo.socketId() }], {}, `auth` );
			if ( res.body.key ) {
				this.client.user.key = res.body.key;
				this.client.ws.subscribe( `auth`, ( c ) => c.whisper( `pusher:auth`, { key: res.body.key } ) );
			}
		});
		
		this.client.on(STATE.WEBSOCK_AUTH_SUCCESS, ( state ) => {
			this.client.ws.subscribe(`user.${this.client.user.id}`, () => this.client.setState( STATE.READY ))
			.on(`game.invite`, (e) => {
				this.navigate( `/play/${e.lobby.id}`, 'Zolmaņiem | Spēlēt' );
			})
			.on(`disconecting`, (e) => {
				console.log(e.reason);
				this.client.setState( STATE.ABANDONED );
			});
		});
		
		this.client.on(STATE.WEBSOCK_AUTH_FAILED,  ( state ) => {
			console.log( `Detected client state change: ${ state }` );
		});

		this.client.on(STATE.ABANDONED,  ( state ) => {
			console.log( `Session has been abandoned: ${ state }` );
		});

		this.client.initialize();
	}

  	session = async(ctr = 0) => {
		while(true) {
			try {
				await this.client.user.session();

				await new Promise((r) => setTimeout(r, 1000 * 60 * 10)); // refresh session every 10 min
			} catch(e) {
				console.error(ctr, e);
				this.session(ctr + 1);
			}
		}
  	}

	refresh = async(ctr = 0) => {
		while(true) {
			try {
				await this.client.user.refresh((user) => { this.client.updateUser(user);  this.forceUpdate() });

				await new Promise((r) => setTimeout(r, 1000 * 10)); // refresh user every 10 sec
			} catch(e) {
				console.error(ctr, e);
				this.refresh(ctr + 1);
			}
		}
	}

	render() {
		// if (user.lobbyKey !== null) {
		//   this.props.history.push(`lobby/${user.lobbyKey}`);
		// }

		let Page = PageNotFound;
		let pageProps = { path: this.path, route: [] };

		for (const route of routes) {
			const result = route.path.exec(this.path);
			if (result) {
				for (let i = 0; i < result.length; ++i) {
					if (i in result) {
						pageProps.route.push(result[i]);
					} else break;
				}

				if (route.can) {
					if (route.can(this.client.user)) {
						Page = route.component;
					} else {
						Page = PageNotFound;
						pageProps = { path: this.path, route: [], title: `403 Nav piekļuves`, subTitle: `Tev nav piekļuves šim saturam.` }
					}
				} else {
					Page = route.component;
				}
				break;
			}
		}

		return (
			<>
				<Modal
					centered
					closable={false}
					zIndex={999999}
					width="85vw"
					visible={this.client.state === STATE.AUTH_FAILED}
					footer={null}
					bodyStyle={{
						position: `relative`,
						minHeight: 725,
						background: `white`,
					}}
					destroyOnClose
				>
					<Login base={this} client={this.client} />
				</Modal>
				<Page {...pageProps} size={this.state.size} navigate={this.navigate} key={this.path} client={this.client} />
			</>
		);
	}
}

export default App;
