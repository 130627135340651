import React from 'react';
import Layout from '../../layout';
import { Typography } from 'antd';
import { FilePdfOutlined, LinkOutlined } from '@ant-design/icons';

class Cooperation extends Layout {
    mobileHeadVh = 15;
    mobileHeadHeight = 150;

    constructor(props) {
        super(props);

        this.ready = false;
        this.links = [
            {
                key: `KLATIENES_TURNIRA_PIEDAVAJUMS`,
                href: `files/KLATIENES_TURNIRA_PIEDAVAJUMS`,
                type: `pdf`,
                title: `Klātienes zolītes turnīra rīkošanas piedāvājums`,
            },
            {
                key: `TIESSAISTES_TURNIRA_PIEDAVAJUMS`,
                href: `files/TIESSAISTES_TURNIRA_PIEDAVAJUMS`,
                type: `pdf`,
                title: `Tiešsaistes zolītes turnīra rīkošanas piedāvājums`,
            },
            {
                key: `analytics`,
                href: `analytics`,
                type: `link`,
                title: `Mājas lapas statistika`,
            },
        ];
    }

    // componentDidMount() {
    // }

    content() {
        const { size } = this.props;
        if (size > 1380) {
            return this.contentWide();
        }

        return this.contentMobile();
    }

    head() {
        return (
            <div style={{
                position: `absolute`,
                height: `max(25vh, 300px)`,
                top: 0,
                left: 0,
                right: 0,
            }}>
                <Typography.Title
                    level={2}
                    style={{
                        color: `#F3DD8E`,
                        fontSize: 36,
                        margin: `4vh 0 30px 0`,
                        textAlign: `center`
                    }}
                >
                    Sadarbība
                </Typography.Title>
            </div>
        );
    }

    contentMobile() {
        // const { user } = this.props.client;

        return (
            <>
                <div style={{ height: 50, width: `100%` }} />
                {
                    this.links.map((item) => (
                        <>
                            <Typography.Link
                                key={item.key}
                                onClick={() => {
                                    if (item.type === `pdf`) {
                                        window.open(`${document.location.origin}/${item.href}`);
                                    } else if (this.props.navigate) {
                                        return this.props.navigate(item.href, item.title);
                                    }
                                }}
                                style={{
                                    cursor: `alias`,
                                    textTransform: `uppercase`,
                                    margin: `5px 0`,
                                    display: `inline-block`,
                                    marginLeft: 40,
                                }}
                            >
                                {item.title}
                                {item.type === `pdf` ? (
                                    <FilePdfOutlined className="pdf-icon" />
                                ) : (
                                    <LinkOutlined className="pdf-icon" />
                                )}
                            </Typography.Link>
                            <br />
                        </>
                    ))
                }
            </>
        );
    }

    contentWide() {
        // const { user } = this.props.client;

        return (
            <>
                <Typography.Title level={2}>Sadarbība</Typography.Title>
                {
                    this.links.map((item) => (
                        <>
                            <Typography.Link
                                key={item.key}
                                onClick={() => {
                                    if (item.type === `pdf`) {
                                        window.open(`${document.location.origin}/${item.href}`);
                                    } else if (this.props.navigate) {
                                        return this.props.navigate(item.href, item.title);
                                    }
                                }}
                                style={{
                                    cursor: `alias`,
                                    textTransform: `uppercase`,
                                    margin: `5px 0`,
                                    display: `inline-block`,
                                    marginLeft: 40,
                                }}
                            >
                                {item.title}
                                {item.type === `pdf` ? (
                                    <FilePdfOutlined className="pdf-icon" />
                                ) : (
                                    <LinkOutlined className="pdf-icon" />
                                )}
                            </Typography.Link>
                            <br />
                        </>
                    ))
                }
            </>
        );
    }
}

export default Cooperation;
