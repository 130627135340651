import React from 'react';
import Layout from '../layout';
import { notification, Typography } from 'antd';
import DailyBonus from '../layout/widgets/DailyBonus';
import TablesPlayed from '../layout/widgets/Tables';
import Leader from '../layout/widgets/Leader';
import Gametable from '../layout/widgets/GameTable';
import CreateRoom from '../layout/widgets/CreateRoom';

class League extends Layout {
    constructor(props) {
        super(props);

        this.ready = false;

        this.roomData = {
            type: `P`,
            bet: 1000,
            minGames: 1,
            isLocked: false,
            isFast: true,
            is4Player: false,
        };

        this.state = {
            pageSize: 3,
            activeRooms: 0,
            openRooms: 0,
            rooms: [],
            page: 1,
        };
    }

    componentDidMount() {
        const { user, ws: websock } = this.props.client;

        window._ws_refresh = () => this.refresh();
        websock.onReady = (ws) => {
            websock.subscribe(`league`, () => new Promise(r => setTimeout(() => { this.refresh(); r(); }, 100)))
                .on(`lobby.created`, (e) => {
                    const { state } = this;

                    let lobbies = [];
                    const isNew = state.rooms.filter(r => r.id === e.id).length === 0;
                    if (!isNew) {
                        lobbies = state.rooms.map(r => r.id === e.id ? { ...e } : { ...r });
                    } else if (state.rooms.length < state.pageSize * state.page) {
                        if (this.includesCurrentUser([ { ...e } ])) {
                            websock.subscribe(`lobby.${e.id}`)
                                .on(`lobby.ready`, (e) => {
                                    this.props.client.game = { id: e.game.id };
                                    this.props.client.lobby = { id: e.lobby.id };
                                    websock.unsubscribe(`league`);
                                    this.navigate( `/play/${e.lobby.id}`, 'Zolmaņiem | Spēlēt' );
                                });
                            lobbies = [ { ...e }, ...state.rooms ];
                        } else {
                            lobbies = [ ...state.rooms, { ...e } ];
                        }
                    }

                    this.setState({ rooms: [ ...lobbies ] });
                })
                .on(`lobby.closed`, (e) => {
                    const { state } = this;

                    if (e.id === user.lobby) {
                        user.setLobby(null);
                    }

                    this.setState({ rooms: state.rooms.filter(r => r.id !== e.id ) });
                    this.refresh();
                })
                .on(`lobby.joined`, (e) => {
                    const { state } = this;

                    const rooms = [];
                    for (const room of state.rooms) {
                        if (room.id === e.lobby.id) {
                            if (room.players.filter(p => p.id === e.user.id).length > 0) {
                                room.players = room.players.map(p => p.id === e.user.id ? { ...e.user } : { ...p });
                            } else {
                                room.players.push({ ...e.user });
                            }

                            if (e.user.id === user.id) {
                                user.setLobby(e.lobby.id);
                            }
                        }

                        if (room.players.length < rooms.is4Player ? 4 : 3) {
                            rooms.push({ ...room });
                        }
                    }

                    this.setState({ rooms: [ ...rooms ] });
                    this.refresh();
                })
                .on(`lobby.left`, (e) => {
                    const { state } = this;

                    const rooms = [];
                    for (const room of state.rooms) {
                        if (room.id === e.lobby.id) {
                            room.players = room.players.filter((p) => p.id !== e.user.id).values();
                            if (user.lobby === room.id) {
                                user.setLobby(null);
                            }
                        }

                        if (room.players.length > 0) {
                            rooms.push({ ...room });
                        }
                    }

                    this.setState({ rooms: [ ...rooms ] });
                    this.refresh();
                })
                .on(`lobby.list`, (e) => {
                    this.includesCurrentUser([ ...e.rooms ])
                    this.setState({ rooms: [ ...e.rooms ] });
                })
                .on(`lobby.started`, (e) => {
                    const { state } = this;

                    this.setState({ rooms: state.rooms.filter(r => r.id !== e.id ) });
                    this.refresh();
                });
            ;
        };
    }

    includesCurrentUser = (lobbies) => {
        const { user, ws: websock } = this.props.client;

        if (user.id && !user.lobby && websock.ready) {
            for (const lobby of lobbies) {
                for (const player of lobby.players) {
                    if (player.id === user.id) {
                        return user.setLobby(lobby.id);
                    }
                }
            }
        }

        return false;
    }

    content() {
        const { size } = this.props;
        if (size > 1380) {
            return this.contentWide();
        }

        return this.contentMobile();
    }

    refresh = () => {
        const { ws: websock } = this.props.client;
        const { state } = this;

        if (websock.ready) {
            websock.subscribe(`league`).whisper(`lobby.reload`, { pages: state.page, size: state.pageSize });
        }
    };

    create = async () => {
        const { user, ws: websock } = this.props.client;

        if (!!user.lobby) return;

        if (websock.ready) {
            websock.subscribe(`league`).whisper(`lobby.create`, { ...this.roomData });
        }
    };

    joinOrLeave = async (id) => {
        const { user, ws: websock } = this.props.client;

        if (websock.ready) {
            if (user.lobby === id) {
                user.lobby = null;
                websock.unsubscribe(`lobby.${id}`);
            } else {
                user.lobby = id;
                websock.subscribe(`lobby.${id}`)
                    .on(`lobby.invite`, (e) => {
                        this.props.client.lobby = { id: e.lobby.id };
                        websock.unsubscribe(`league`);
                        this.navigate( `/play/${e.lobby.id}`, 'Zolmaņiem | Spēlēt' );
                    }).on(`game.invite`, (e) => {
                        this.props.client.game = { id: e.game.id };
                        websock.unsubscribe(`league`);
                        this.navigate( `/play/${e.lobby.id}`, 'Zolmaņiem | Spēlēt' );
                    }).on(`lobby.ready`, (e) => {
                        this.props.client.game = { id: e.game.id };
                        this.props.client.lobby = { id: e.lobby.id };
                        websock.unsubscribe(`league`);
                        this.navigate( `/play/${e.lobby.id}`, 'Zolmaņiem | Spēlēt' );
                    });
            }
        }
    };

    head() {
        const { user } = this.props.client;

        return (
            <div style={{
                position: `absolute`,
                height: `max(25vh, 300px)`,
                top: 0,
                left: 0,
                right: 0,
            }}>
                <Typography.Title
                    level={2}
                    style={{
                        color: `#F3DD8E`,
                        fontSize: 36,
                        margin: `4vh 0 30px 0`,
                        textAlign: `center`
                    }}
                >
                    Zolmaņu līga
                </Typography.Title>
                <div style={{
                    padding: `0 10px`,
                    display: `flex`,
                    width: `100%`,
                    justifyContent: `center`
                }}>
                    <div
                        style={{
                            background: `#E2C354`,
                            minWidth: 100,
                            maxWidth: 160,
                            width: `33vw`,
                            minHeight: 230,
                            maxHeight: 230,
                            boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
                            borderRadius: 6,
                            paddingTop: 20,
                            zIndex: 9,
                            margin: `0 ${this.props.size > 575 ? `10` : `5`}px`,
                        }}
                    >
                        <DailyBonus day={user.dailyBonus} size={this.props.size} />
                    </div>
                    <div
                        style={{
                            background: `#E2C354`,
                            minWidth: 100,
                            maxWidth: 160,
                            width: `33vw`,
                            minHeight: 230,
                            maxHeight: 230,
                            boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
                            borderRadius: 6,
                            paddingTop: 20,
                            zIndex: 9,
                            margin: `0 ${this.props.size > 575 ? `10` : `5`}px`,
                        }}
                    >
                        <TablesPlayed count={0} size={this.props.size} />
                    </div>
                    <div
                        style={{
                            background: `#E2C354`,
                            minWidth: 100,
                            maxWidth: 160,
                            width: `33vw`,
                            minHeight: 230,
                            maxHeight: 230,
                            boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
                            borderRadius: 6,
                            paddingTop: 20,
                            zIndex: 9,
                            margin: `0 ${this.props.size > 575 ? `10` : `5`}px`,
                        }}
                    >
                        <Leader name="TEST ONE" count={0} size={this.props.size} />
                    </div>
                </div>
            </div>
        );
    }

    contentMobile() {
        const { user } = this.props.client;

        return (
            <>
                <div style={{ height: 50, width: `100%` }} />
                <div style={{
                    display: `table`,
                    margin: `auto`,
                    maxWidth: 800,
                }}>
                    <Gametable
                        style={{ display: `block`, maxWidth: 800 }}
                        tableStyle={{ maxWidth: 800, width: `100%` }}
                        data={this.state.rooms}
                        size={this.state.openRooms}
                        onMore={() => this.setState(old => ({ page: old.page + 1 }))}
                        onJoin={(id) => this.joinOrLeave(id)}
                        client={this.props.client}
                    />
                    <CreateRoom callback={(data) => this.roomData = { ...data }} />
                    <div
                        className={`button${user.lobby ? ` inactive` : ``}`}
                        style={{
                            display: `inline-block`,
                            marginTop: 35,
                            marginLeft: 10,
                        }}
                        onClick={() => this.create()}
                    >
                        Izveidot galdu
                    </div>
                    <Typography.Text style={{
                        color: `#606060`,
                        fontSize: 16,
                        display: `block`,
                        marginTop: 50,
                        marginLeft: 10,
                        maxWidth: 800,
                        width: `100%`,
                    }}>
                        P - ar pulēm, G - ar galdiņu, PM - ar pulēm un mazo zoli, GM - ar galdiņu un mazo zoli, 4 - zole četratā, cipars - minimālais partiju skaits,      - veiklā,
                        - draugu galds
                    </Typography.Text>
                </div>
            </>
        );
    }

    contentWide() {
        const { user } = this.props.client;

        return (
            <>
                <table
                    style={{
                        width: `100%`,
                    }}
                >
                    <tr>
                        <td
                            rowSpan={2}
                            style={{
                                verticalAlign: `top`,
                                width: 200,
                            }}
                        >
                            <div
                                style={{
                                    background: `#E2C354`,
                                    width: 160,
                                    minHeight: 400,
                                    boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
                                    borderRadius: 6,
                                    paddingTop: 35,
                                }}
                            >
                                <DailyBonus day={user.dailyBonus} size={this.props.size} />
                                <TablesPlayed count={0} size={this.props.size} />
                                <Leader name="Jānis Pirmais" count={1234} size={this.props.size} />
                                <br />
                            </div>
                        </td>
                        <td
                            style={{
                                verticalAlign: `top`,
                            }}
                        >
                            <Typography.Title level={2}>Zolmaņu līga</Typography.Title>
                            <Gametable
                                data={this.state.rooms}
                                size={this.state.openRooms}
                                onMore={() => this.setState(old => ({ page: old.page + 1 }))}
                                onJoin={(id) => this.joinOrLeave(id)}
                                client={this.props.client}
                            />
                            <CreateRoom callback={(data) => this.roomData = { ...data }} />
                            <div
                                className={`button${user.lobby ? ` inactive` : ``}`}
                                style={{
                                    display: `inline-block`,
                                    marginTop: 35,
                                    marginLeft: 10,
                                }}
                                onClick={() => this.create()}
                            >
                                Izveidot galdu
                            </div>
                            <Typography.Text style={{
                                color: `#606060`,
                                fontSize: 16,
                                display: `block`,
                                marginTop: 50,
                                marginLeft: 10,
                            }}>
                                P - ar pulēm, G - ar galdiņu, PM - ar pulēm un mazo zoli, GM - ar galdiņu un mazo zoli, 4 - zole četratā, cipars - minimālais partiju skaits,      - veiklā,
                                - draugu galds
                            </Typography.Text>
                        </td>
                    </tr>
                </table>
            </>
        );
    }
}

export default League;
