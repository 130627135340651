import React from 'react';
import Layout from '../../layout';
import { Typography } from 'antd';
import { BlockOutlined, CalendarOutlined, FolderOpenOutlined, NotificationOutlined, SettingOutlined, TeamOutlined } from '@ant-design/icons';
import MobileMenuCard from '../../layout/widgets/MobileMenuCard';

class Admin extends Layout {
    constructor(props) {
        super(props);

        this.routes = [
            {
                icon: TeamOutlined,
                left: 22,
                title: `Lietotāji`,
                path: `users`,
            },
            {
                icon: CalendarOutlined,
                left: 22,
                title: `Turnīri`,
                path: `tourneys`,
            },
            {
                icon: SettingOutlined,
                left: 22,
                top: 22,
                title: `Iestatījumi`,
                path: `settings`,
            },
            {
                icon: FolderOpenOutlined,
                left: 25,
                title: `Faili`,
                path: `files`,
            },
            {
                icon: BlockOutlined,
                left: 22,
                top: 22,
                title: `Saturs`,
                path: `footer`,
            },
            {
                icon: NotificationOutlined,
                title: `Ziņojumi`,
                path: `logs`,
            }
        ];
    }

    // componentDidMount() {
    // }

    content() {
        const { size } = this.props;
        if (size > 1380) {
            return this.contentWide();
        }

        return this.contentMobile();
    }

    head() {
        return (
            <div style={{
                position: `absolute`,
                height: `max(25vh, 300px)`,
                top: 0,
                left: 0,
                right: 0,
            }}>
                <Typography.Title
                    level={2}
                    style={{
                        color: `#F3DD8E`,
                        fontSize: 36,
                        margin: `4vh 0 30px 0`,
                        textAlign: `center`
                    }}
                >
                    Administratora panelis
                </Typography.Title>
                <div style={{
                    padding: `0 10px`,
                    display: `flex`,
                    width: `100%`,
                    justifyContent: `center`
                }}>
                    {
                        this.routes.map((route) =>
                            <MobileMenuCard
                                navigate={this.navigate}
                                {...route}
                            />
                        )
                    }
                </div>
            </div>
        );
    }

    contentMobile() {
        return (
            <>
                <div style={{ height: 50, width: `100%` }} />
                <div style={{
                    display: `table`,
                    margin: `auto`,
                    maxWidth: 800,
                }}>
                </div>
            </>
        );
    }

    contentWide() {
        // const { user } = this.props.client;

        return (
            <>
                <Typography.Title level={2}>Administratora panelis</Typography.Title>
                <div style={{
                    padding: `0 10px`,
                    display: `flex`,
                    width: `100%`,
                    justifyContent: `space-evenly`,
                    marginTop: 60,
                }}>
                    {
                        this.routes.map((route) =>
                            <MobileMenuCard
                                navigate={this.navigate}
                                {...route}
                            />
                        )
                    }
                </div>
            </>
        );
    }
}

export default Admin;
