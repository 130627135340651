import React from 'react';
import Layout from '../layout';
import { Result } from 'antd';

class PageNotFound extends Layout {
    mobileHeadVh = 15;
    mobileHeadHeight = 300;

    content() {
        const { size } = this.props;
        if (size > 1380) {
            return this.contentWide();
        }

        return this.contentMobile();
    }

    head() {
        return null;
    }

    contentMobile() {
        return (
            <div className="error" style={{ marginTop: 60 }}>
                <Result
                    status="error"
                    title={this.props.title ?? `404 Lapa nav atrasta`}
                    subTitle={this.props.subTitle ?? `Šāda lapa nav atrasta`}
                    extra={null}
                />
            </div>
        );
    }

    contentWide() {
        return (
            <div className="error" style={{ marginTop: 60 }}>
                <Result
                    status="error"
                    title={this.props.title ?? `404 Lapa nav atrasta`}
                    subTitle={this.props.subTitle ?? `Šāda lapa nav atrasta`}
                    extra={null}
                />
            </div>
        );
    }
}

export default PageNotFound;
