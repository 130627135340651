import { message, notification, Typography } from 'antd';
import React from 'react';
import Layout from '../layout';
import moment from 'moment';
import Points from '../layout/widgets/Points';

import logo from '../resources/logo.png';
import timer from '../resources/timer.svg';
import calendar from '../resources/calendar.svg';
import clock from '../resources/clock.svg';

class Tourney extends Layout {
    constructor(props) {
        super(props);

        this.state = {
            tourneys: [
                // {
                //     id: 1,
                //     logo: true,
                //     open: true,
                //     name: `Darba dienu turnīrs`,
                //     author: {
                //         name: `Zolmaniem.lv`,
                //     },
                //     stages: 4,
                //     games: 6,
                //     fast: true,
                //     minLevel: 0,
                //     fee: 1,
                //     price: {
                //         type: `ZD`,
                //         ammount: 1000000,
                //     },
                //     date: [
                //         `09-06-2021 19:00:00`,
                //         `09-07-2021 19:00:00`,
                //         `09-08-2021 19:00:00`,
                //         `09-13-2021 19:00:00`,
                //     ],
                //     type: `GM`,
                //     break: 5,
                // },
                // {
                //     id: 2,
                //     logo: true,
                //     open: true,
                //     name: `Darba dienu turnīrs`,
                //     author: {
                //         name: `Zolmaniem.lv`,
                //     },
                //     stages: 4,
                //     games: 6,
                //     fast: true,
                //     minLevel: 0,
                //     fee: 1,
                //     price: {
                //         type: `ZD`,
                //         ammount: 1000000,
                //     },
                //     date: [
                //         `09-06-2021 19:00:00`,
                //         `09-07-2021 19:00:00`,
                //         `09-08-2021 19:00:00`,
                //         `09-13-2021 19:00:00`,
                //     ],
                //     type: `GM`,
                //     break: 5,
                // },
                // {
                //     id: 3,
                //     logo: true,
                //     open: true,
                //     name: `Darba dienu turnīrs`,
                //     author: {
                //         name: `Zolmaniem.lv`,
                //     },
                //     stages: 4,
                //     games: 6,
                //     fast: true,
                //     minLevel: 0,
                //     fee: 1,
                //     type: `GM`,
                //     price: {
                //         type: `ZD`,
                //         ammount: 1000000,
                //     },
                //     date: [
                //         `09-06-2021 19:00:00`,
                //         `09-07-2021 19:00:00`,
                //         `09-08-2021 19:00:00`,
                //         `09-13-2021 19:00:00`,
                //     ],
                //     break: 5,
                // },
            ],
        };
    }

    componentDidMount() {
        this.fetchTourneys();
    }

    fetchTourneys = async () => {
        const response = await this.props.client.get( `tourneys`, 1, 5 );
        if (response.ok && response.status === 200) {
            this.setState({ tourneys: [ ...response.body ] });
        }
    }

    content() {
        const { size } = this.props;
        if (size > 1380) {
            return this.contentWide();
        }

        return this.contentMobile();
    }

    head() {
        return null;
    }

    contentMobile() {
        return this.contentWide();
        // return (
        //     <>
        //         <Typography.Title
        //             level={2}
        //             style={{
        //                 color: `#50027E`,
        //                 fontSize: 40,
        //                 fontFamily: `Montserrat`,
        //                 margin: `35px 0 0 0`,
        //                 textTransform: `uppercase`,
        //                 fontWeight: 600,
        //             }}
        //         >
        //             Turnīri
        //         </Typography.Title>
        //     </>
        // );
    }

    contentWide() {
        const { state } = this;

        const headers = [
            <>
                {
                    
                }
                <div
                    className="button"
                    style={{
                        width: `fit-content`,
                        margin: `15px`,
                    }}
                    onClick={() => this.props.navigate(`/tourney/new`, `Zolmaniem | Jauns turnīrs`)}
                >
                    + Izveidot
                </div>
            </>,
            `Pieejamība`,
            `Nosaukums`,
            `Pasūtītājs`,
            `Līmenis`,
            `Spēles veids`,
            `Balva`,
            `Dalības maksa`,
            `Norises laiks`,
            ``,
        ];
        const rdata = [];

        for (const i in state.tourneys) {
            for (let j = 0; j < headers.length; ++j) {
                if ( !(j in rdata) ) {
                    rdata[j] = [ headers[j] ];
                }
            }

            rdata[0].push(
                state.tourneys[i].id > 0 ? <img src={logo} alt="logo" style={{ maxWidth: 105, margin: `35px auto 15px auto`, display: `block` }} /> : <></>
            );
            rdata[1].push(!!state.tourneys[i].public ? `Publisks` : `Privāts`);
            rdata[2].push(state.tourneys[i].name);
            rdata[3].push(
                <>
                    { !!state.tourneys[i].orgName && <><span>{state.tourneys[i].orgName}</span><br /></> }
                    { !!state.tourneys[i].orgRegNr && <><span>{state.tourneys[i].orgRegNr}</span><br /></> }
                    {/* { !!state.tourneys[i].author.agent && (
                            <>
                                <span>{state.tourneys[i].author.agent.fullName}</span> e-pasts <span>{state.tourneys[i].author.agent.email}</span><br />
                                <span>Tālr. {state.tourneys[i].author.agent.mobile}</span><br />
                            </>
                        )
                    } */}
                    { !!state.tourneys[i].orgNotes && <><span>{state.tourneys[i].orgNotes}</span></> }
                </>
            );
            rdata[4].push(state.tourneys[i].minLevel ?? <em>Nav norādīts</em>);
            rdata[5].push(
                <>
                    {state.tourneys[i].games > 1 && `${state.tourneys[i].games} `}
                    {state.tourneys[i].gameType}
                    {!!state.tourneys[i].quick && <img src={timer} alt="timer" style={{ width: 15, marginTop: -3, marginLeft: 5 }} />}
                </>
            );
            rdata[6].push(
                <><Points points={state.tourneys[i].price} /> ZD</>
                // !!state.tourneys[i].price ? (
                //     <>
                //     {
                //         !!state.tourneys[i].price.ammount && !!state.tourneys[i].price.type ? (
                //             <>
                //                 <Points points={state.tourneys[i].price.ammount} /> {state.tourneys[i].price.type}
                //             </>
                //         ) : (
                //             !!state.tourneys[i].price.info ? (
                //                 <>
                //                     {state.tourneys[i].price.info}
                //                 </>
                //             ) : <em>-</em>
                //         )
                //     }
                //     </>
                // ) : <em>-</em>
            );
            rdata[7].push(
                <><Points points={state.tourneys[i].fee} /> ZD</>
                // !!state.tourneys[i].fee ? (
                //     <>
                //     {
                //         !!state.tourneys[i].fee.ammount && !!state.tourneys[i].fee.type ? (
                //             <>
                //                 <Points points={state.tourneys[i].fee.ammount} /> {state.tourneys[i].fee.type}
                //             </>
                //         ) : (
                //             !!state.tourneys[i].fee.info ? (
                //                 <>
                //                     {state.tourneys[i].fee.info}
                //                 </>
                //             ) : <em>-</em>
                //         )
                //     }
                //     </>
                // ) : <em>-</em>
            );
                
            const times = [];
            if (state.tourneys[i].cycles && Array.isArray(state.tourneys[i].cycles)) {
                let start = moment(state.tourneys[i].cycles[0].date);

                const maxMoveCount = 3 + 1 + 3 * 8; // 3 spēlētājiem jārunā, jāizspēlē vismaz 24 kārtis + 1, ja lielajam jānorokas
                const maxMoveLength = (state.tourneys[i].quick ? 10 : 30) + 1; // Maksimālais 1 gājiena laiks + max 1sec drošībai pie noslogojuma
                const maxGameLength = maxMoveCount * maxMoveLength;
                const maxStageLength = Math.ceil(maxGameLength * state.tourneys[i].games / 60 / 3 / 5) * 5;

                // console.log(start, maxMoveCount, maxMoveLength, maxGameLength, maxStageLength);

                for (let k = 0; k < state.tourneys[i].stages; k++) {
                    let end = start.clone().add(maxStageLength, `minutes`);

                    times.push(`${k + 1}. ${start.format(`HH:mm`)} - ${end.format(`HH:mm`)}`);
                    if (k + 1 !== state.tourneys[i].stages) {
                        times.push(`${state.tourneys[i].break ?? 5} min`)
                    }
                    start = end.clone().add(state.tourneys[i].break ?? 5, `minutes`);
                }
            }
            rdata[8].push(
                Array.isArray(state.tourneys[i].cycles) ? (
                    <>
                        <div style={{ display: `flex`, gap: 5 }}>
                            <div>
                                <img src={calendar} alt="calendar" style={{ width: 16 }} />
                            </div>
                            <div>
                                {
                                    state.tourneys[i].cycles.map((c) => (
                                            <><span>{moment(c.date).format(`DD.MM.YYYY`)}</span><br /></>
                                        )
                                    )
                                }
                            </div>
                        </div>
                        <div style={{ display: `flex`, gap: 5, marginTop: 10 }}>
                            <div>
                                <img src={clock} alt="clock" style={{ width: 16 }} />
                            </div>
                            <div>
                                {
                                    times.map((time) => (
                                            <><span>{time}</span><br /></>
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </>
                ) : <>{state.tourneys[i].cycles}</>
            );
            rdata[9].push(
                <div
                    className="button"
                    style={{
                        width: `fit-content`,
                        margin: `15px`,
                    }}
                    onClick={async () => {
                        const m = state.tourneys[i].joined ? `leave` : `register`;
                        const mm = state.tourneys[i].joined ? `pamest` : `reģistrēties`;
                        const response = await this.props.client.post(`tourneys/${state.tourneys[i].id}/${m}`, null);

                        if (response.ok && response.status === 200) {
                            this.props.navigate(`/tourney/${state.tourneys[i].id}`, `Zolmaniem | Turnīrs`);
                        } else {
                            notification.destroy();
                            notification.error({
                                duration: 60,
                                message: response.body.title ?? `Mēģiniet vēlreiz!`,
                                description: response.body.error ?? `Kaut kas nogāja greizi - neizdevās ${mm} turnīru.`,
                            });
                        }
                    }}
                >
                    {state.tourneys[i].joined ? `Pamest` : `Reģistrēties`}
                </div>
            );
        }

        return (
            <>
                <Typography.Title
                    level={2}
                    style={{
                        color: `#50027E`,
                        fontSize: 40,
                        fontFamily: `Montserrat`,
                        margin: `60px 0 0 0`,
                        textTransform: `uppercase`,
                        fontWeight: 600,
                    }}
                >
                    Turnīri
                </Typography.Title>
                {
                    !!rdata.length ? (
                        <table style={{ minWidth: `60%`, marginTop: 30 }}>
                            {
                                rdata.map((row, i) => (
                                    <tr>
                                        {
                                            row.map((cell, j) => (
                                                <td
                                                    style={{
                                                        cursor: i === 0 ? `pointer` : `default`,
                                                        padding: `8px 10px`,
                                                        fontSize: 15,
                                                        color: j === 0 && i !== 0 ? `#E2C354` : `#606060`,
                                                        fontWeight: j === 0 && i !== 0 ? `700` : `500`,
                                                        background: j === 0 && i !== 0 ? `#8A30BF` : `white`,
                                                        border: `1px solid #606060`,
                                                        minWidth: 130,
                                                        verticalAlign: j !== 0 && i !== 0 ? `top` : `middle`,
                                                    }}
                                                >
                                                    {cell}
                                                </td>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </table>
                    ) : (
                        <>
                            <em>Nav neviena turnīra</em>
                            <div
                                className="button"
                                style={{
                                    width: `fit-content`,
                                    margin: `15px`,
                                }}
                                onClick={() => this.props.navigate(`/tourney/new`, `Zolmaniem | Jauns turnīrs`)}
                            >
                                + Izveidot
                            </div>
                        </>
                    )
                }
            </>
        );
    }
}

export default Tourney;
