import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, Table, Typography } from 'antd';
import React from 'react';
import Layout from '../layout';
import moment from 'moment';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Statistic from '../layout/widgets/Statistic';

const { Link } = Typography;

class Achievements extends Layout {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    content() {
        const { size } = this.props;
        if (size > 1380) {
            return this.contentWide();
        }

        return this.contentWide();
    }

    head() {
        return null;
    }

    contentMobile() {
        return (
            <>
                <Typography.Title
                    level={2}
                    style={{
                        color: `#50027E`,
                        fontSize: 40,
                        fontFamily: `Montserrat`,
                        margin: `35px 0 0 0`,
                        textTransform: `uppercase`,
                        fontWeight: 600,
                    }}
                >
                    Jauns turnīrs
                </Typography.Title>
            </>
        );
    }

    contentWide() {
        const { user } = this.props.client;
        
        const screen = {
            width: document.body.offsetWidth,
            height: document.body.offsetHeight,
            ratio: document.body.offsetWidth / document.body.offsetHeight,
        };
        
        let colPerRow = 6;
        if (screen.width <= 330) {
            colPerRow = 1;
        } else if (screen.width <= 400) {
            colPerRow = 2;
        } else if (screen.width <= 550) {
            colPerRow = 3;
        } else if (screen.width <= 850) {
            colPerRow = 4;
        }

        const rows = [];
        for (let col = 0, row = 0, i = 0; i < user.achievements.length; i++) {
            if (col === 0) {
                rows[row] = [];
            }

            rows[row][col] = user.achievements[i];
            if (++col === colPerRow) {
                row++;
                col = 0;
            }
        }

        console.log(rows);

        return (
            <>
                <Typography.Title
                    level={2}
                    style={{
                        color: `#50027E`,
                        fontSize: 40,
                        fontFamily: `Montserrat`,
                        margin: `35px 0 0 0`,
                        textTransform: `uppercase`,
                        fontWeight: 600,
                    }}
                >
                    Sasniegumi
                </Typography.Title>
                <br />
                {
                    rows.map(row => (
                        <Row>
                            {
                                row.map(col => (
                                    <Col span={24 / colPerRow} title={col.description} style={{ filter: !col.achieved_at ? `grayscale(1)` : undefined }}>
                                        <Statistic achievement={col} />
                                    </Col>
                                ))
                            }
                        </Row>
                    ))
                }
            </>
        );
    }
}

export default Achievements;
