import User from "./user";
import WebSock from "./websocket";

export const required = { required: true, message: 'Šis lauks ir obligāts.' };

export const STATE = {
    NONE:               `NONE`,
    LOADING:            `LOADING`,
        AUTH:                   `AUTH`,
        AUTH_SUCCESS:           `AUTH-SUCCESS`,
        AUTH_FAILED:            `AUTH-FAILED`,
        WEBSOCK_CONNECTING:     `WEBSOCK-CONNECTING`,
        WEBSOCK_FAILED:         `WEBSOCK-FAILED`,
        WEBSOCK_UNAVAILABLE:    `WEBSOCK-UNAVAILABLE`,
        WEBSOCK_CONNECTED:      `WEBSOCK-CONNECTED`,
        WEBSOCK_AUTH:           `WEBSOCK-AUTH`,
        WEBSOCK_AUTH_SUCCESS:   `WEBSOCK-AUTH-SUCCESS`,
        WEBSOCK_AUTH_FAILED:    `WEBSOCK-AUTH-FAILED`,
    READY:              `READY`,
    ABANDONED:          `ABANDONED`,

    LOGOUT_SUCCESS:     `LOGOUT-SUCCESS`,
    LOGOUT_FAILED:      `LOGOUT-FAILED`,

    LEAGUE:             `LEAGUE`,
    LOBBY:              `LOBBY`,
    T_INGAME:           `T-INGAME`,
    Z_INGAME:           `Z-INGAME`,
};

export default class Client {
    host = ``;

    state = STATE.NONE;

    user = null;
    ws = null;

    game = null;
    lobby = null;

    _listeners = [];

    constructor(debug = false) {
        this.debug = debug;
    
        this.user = new User(this);
        this.ws = new WebSock(this);

        if (window.location.hostname === `localhost`) {
            this.host = `http://localhost:5000`;
        }
    }

    updateUser = (user) => {
        this.user = user;
    }

    on = (state, listener = (state) => console.log(`Client state change: ${state}`)) => {
        if ( !(state in this._listeners) || !Array.isArray(this._listeners[state]) ) {
            this._listeners[state] = [];
        }

        this._listeners[state].push(listener);
    };

    setState = (state) => {
        this.state = state;

        console.log(state);

        if ( !(state in this._listeners) || !Array.isArray(this._listeners[state]) ) {
            this._listeners[state] = [];
        }

        for (const handler of this._listeners[state]) {
            try {
                handler(state);
            } catch (ex) {
                console.error(ex);
            }
        }
    };

    initialize = (force = false) => {
        if (this.state === STATE.NONE || this.state === STATE.LOADING || force) {

            this.setState(STATE.AUTH);
            this.user.authorize(
                async (usr) => {
                    this.setState(STATE.AUTH_SUCCESS);
                },
                (code, usr) => {
                    console.warn( `Authorization failed with code "${code}"`, { ...usr } );
                    this.setState(STATE.AUTH_FAILED);
                }
            );
        }
    };

    get = (source, page = 1, size = 10, extra = [], headers = {}, version = `v1`) => {  
        if (size) extra.unshift({ key: `size`, value: size });
        if (page) extra.unshift({ key: `page`, value: page });
    
        return new Promise((r) => {
            fetch(
                `${this.host}/api/${version}/${source}${extra.length ? `?` : ``}${extra
                    .map((p) => `${p.key}=${p.value}`)
                    .join(`&`)}`,
                {
                    headers: {
                        Accept: `application/json`,
                        "Content-Type": `application/json`,
                        Authorization: this.user?.token ?? null,
                        ...headers,
                    },
                }
            )
                .then(async (data) => {
                    const response = { ok: data.ok, status: data.status };
                    const _data = await data.json();
                    r({ ...response, body: _data });
                })
                .catch((err) => console.error(err));
        });
    };

    post = (route, item, headers = {}, version = `v1`, isJsonData = true) => {
        return new Promise((r, f) => {
            fetch(`${this.host}/api/${version}/${route}`, {
                method: `POST`,
                headers: {
                    Accept: `application/json`,
                    ...(isJsonData ? {"Content-Type": `application/json`} : {}),
                    Authorization: this.user?.token ?? null,
                    ...headers,
                },
                body: isJsonData ? JSON.stringify(item ?? {}) : item,
            })
                .then(async (data) => {
                    const response = { ok: data.ok, status: data.status };
                    const _data = await data.json();
                    r({ ...response, body: _data });
                })
                .then((data) => r(data))
                .catch((err) => f(err));
        });
    };

    patch = (route, id, item, headers = {}, version = `v1`) => {
        return new Promise((r, f) => {
            fetch(`${this.host}/api/${version}/${route}/${id}`, {
                method: `PATCH`,
                headers: {
                    Accept: `application/json`,
                    "Content-Type": `application/json`,
                    Authorization: this.user?.token ?? null,
                    ...headers,
                },
                body: JSON.stringify(item ?? {}),
            })
                .then(async (data) => {
                    const response = { ok: data.ok, status: data.status };
                    const _data = await data.json();
                    r({ ...response, body: _data });
                })
                .then((data) => r(data))
                .catch((err) => f(err));
        });
    };

    delete = (route, id, headers = {}, version = `v1`) => {
        return new Promise((r, f) => {
            fetch(`${this.host}/api/${version}/${route}/${id}`, {
                method: `DELETE`,
                headers: {
                    Accept: `application/json`,
                    "Content-Type": `application/json`,
                    Authorization: this.user?.token ?? null,
                    ...headers,
                },
            })
                .then(async (data) => {
                    const response = { ok: data.ok, status: data.status };
                    const _data = await data.json();
                    r({ ...response, body: _data });
                })
                .then((data) => r(data))
                .catch((err) => f(err));
        });
    };
};
