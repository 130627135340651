import React, { Component } from 'react';
import LayoutMobile from './LayoutMobile';
import LayoutWide from './LayoutWide';

class PageLayout extends Component {
    mobileHeadVh = 25;
    mobileHeadHeight = 300;

    content() {
        return (
            <h2>Zolmaņiem</h2>
        );
    }

    navigate = (url, title = null) => {
        if (this.props.navigate) {
            return this.props.navigate(url, title);
        }

        return false;
    };

    head() {
        return <></>;
    }

    render() {
        if (this.props.size > 1380) {
            return <LayoutWide {...this.props}>{this.content()}</LayoutWide>
        }

        return <LayoutMobile {...this.props} headVh={this.mobileHeadVh} headHeight={this.mobileHeadHeight} head={this.head()}>{this.content()}</LayoutMobile>;
    }
}

export default PageLayout;
