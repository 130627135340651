import React from 'react';

const Points = ({points, ...props}) => {
    var bet = [...`${points}`].reverse();
    var betTxt = [];
    for (let i = 0; i < bet.length; i++) {
        if (i % 3 === 0 && i > 0) {
            betTxt.push(`'`);
        }
        betTxt.push(`${bet[i]}`);
    }
    betTxt = betTxt.reverse().join('');

    return <span {...props}>{betTxt}</span>;
}

export default Points;
